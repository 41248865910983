import React from "react";
import { Section } from "../components/section";

export function SolutionOverview() {
    return (
        <Section id="solution-overview">
            <h2>Solution Overview</h2>
            <p>
                The Digital Rheometer combines data from the polyethylene
                production processes and corresponding extruder sensors to
                create an inferential machine learning model. The model is used
                to predict the current Melt Index for resins produced on the
                extruder on a five-minute basis.
            </p>

            <p className="bolded">Dependent (Target) Model Variable: </p>
            <p className="value">Melt Index</p>

            <br />

            <p className="bolded">Prediction Interval</p>
            <p className="value">5 Minutes</p>
        </Section>
    );
}
