export enum AspectRatio {
    "1:1",
    "2:1",
    "3:1",
    "3:2",
    "4:3",
    "16:7",
    "16:9",
    "16:10",
    "4:1"
}
