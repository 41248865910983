import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./styles.scss";

interface NotificationIconProps {
    className?: string;
    notifications?: number;
}

export default function NotificationIcon({
    className,
    notifications
}: NotificationIconProps): JSX.Element {
    let cn = "notification-icon";
    if (className) {
        cn += className;
    }

    const validNotifications = !!notifications || notifications !== 0;

    return (
        <div className={cn}>
            {validNotifications && (
                <span className="badge-container">
                    <span className="badge">{String(notifications)}</span>
                </span>
            )}
            <FontAwesomeIcon icon={faBell as IconProp} className="icon" />
        </div>
    );
}
