import { AxisMode } from "./line-chart/models/axis-mode";
import {
    Axis,
    Margin,
    Tick,
    TicksConfig
} from "./line-chart/models/line-chart-types";
import { getScaledX } from "./line-chart/utilities/scaled-x";
import { getScaledY } from "./line-chart/utilities/scaled-y";
import { XType } from "./line-chart/models/x-type";
import { precisionRound } from "@utilities/math";

const tickFactory = function getAxisTicks(
    origin: XType,
    end: XType,
    ticksConfig: TicksConfig | null,
    width: number,
    innerWidth: number,
    height: number,
    innerHeight: number,
    margin: Margin,
    axis: "y" | "x",
    axisProperties: Axis
): Tick[] {
    const nOrigin =
        axisProperties.mode === AxisMode.number
            ? (origin as number)
            : (origin as Date).getTime();
    const nEnd =
        axisProperties.mode === AxisMode.number
            ? (end as number)
            : (end as Date).getTime();

    const { labelOverride, numberOfTicks } = ticksConfig as TicksConfig;

    const numTicks = numberOfTicks || 9;

    const positions = getAxisPositions(nOrigin, nEnd, numTicks);

    const ticks: Tick[] = [];

    positions.map((position: AxisMode, index) => {
        let label: string | number = position;
        let indicator: JSX.Element[] | undefined = undefined;
        if (labelOverride) {
            label = labelOverride(position, index);
        }
        if (axisProperties.labelFormatter && label) {
            label = axisProperties.labelFormatter(position);
        }
        if (axis === "x") {
            const scaledX = getScaledX(
                position,
                nOrigin,
                nEnd,
                innerWidth,
                margin.left
            );

            indicator = axisProperties.indicators
                ?.filter((ind) => ind.determinantFunction(position, index))
                .map((ind) =>
                    ind.indicatorFactory(
                        scaledX,
                        height,
                        index,
                        positions,
                        position
                    )
                );

            const tick: Tick = {
                label,
                x1: scaledX,
                x2: scaledX,
                y1: margin.top,
                y2: height - margin.bottom,
                indicator
            };
            ticks.push(tick);
        } else {
            const scaledY = getScaledY(
                position,
                nOrigin,
                nEnd,
                innerHeight,
                margin.top
            );
            if (!scaledY) {
                return;
            }
            const tick: Tick = {
                label,
                x1: margin.left,
                x2: width - margin.right,
                y1: scaledY,
                y2: scaledY,
                indicator
            };
            ticks.push(tick);
        }
    });
    return ticks;
};

function getAxisPositions(
    origin: number,
    end: number,
    numTicks: number
): number[] {
    const range = precisionRound(end - origin, 2);
    const increment = range / (numTicks - 1);

    const ticks = [];
    for (let x = 0; x < numTicks; x++) {
        const tick = precisionRound(x * increment + origin, 4);
        ticks.push(tick);
    }

    return ticks;
}

export default tickFactory;
