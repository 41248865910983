/**
 * Returns the time until the next refresh in milliseconds
 */
export function getTimeUntilRefresh(targetTimeAsString: string): number {
    const targetTime = new Date();
    const [hours, minutes, seconds] = targetTimeAsString.split(":");
    targetTime.setHours(Number(hours));
    targetTime.setMinutes(Number(minutes));
    targetTime.setSeconds(Number(seconds));

    const currentTime = new Date();
    const timeUntilRefreshInMS = targetTime.getTime() - currentTime.getTime();

    if (timeUntilRefreshInMS < 0) {
        targetTime.setDate(targetTime.getDate() + 1);
        return targetTime.getTime() - currentTime.getTime();
    }

    return timeUntilRefreshInMS;
}
