import React from "react";
import { Modal, ModalContent, ModalHeader } from "@cpchem/covalence-ui";
import { toKebabCase } from "@utilities/string-format";
import { Charts_Container_Elements } from "./elements";

interface SupportedResinsModalProps {
    isOpen: boolean;
    onRequestModalClose: () => void;
    modalInfo: {
        extruder: string;
        supportedResins: string[];
    };
}

function SupportedResinsModal({
    isOpen,
    onRequestModalClose,
    modalInfo
}: SupportedResinsModalProps) {
    const { supportedResins, extruder } = modalInfo;
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestModalClose}
            title="Supported Resins"
            shouldCloseOnOverlayClick
            testId={Charts_Container_Elements.Supported_Resins_Modal}
        >
            <ModalHeader className="header">
                <p>
                    The following resins have been trained for{" "}
                    <strong>{extruder}</strong>
                </p>
            </ModalHeader>
            <ModalContent className="content">
                <ul>
                    {supportedResins.map((resin) => (
                        <li key={toKebabCase(resin)}>{resin}</li>
                    ))}
                </ul>
            </ModalContent>
        </Modal>
    );
}

export default SupportedResinsModal;
