import equal from "fast-deep-equal";
import {
    RheometerService,
    DataPoint,
    ApiResponse,
    Message,
    PredictionDataPoint,
    LabMIDataPoint
} from "@services";
import { getCurrentDate, addHours } from "@utilities/date";
import {
    nextForcedEndDate,
    ChartActions,
    nextPauseState,
    ChartEngineException,
    nextChartRangeState,
    ChartState
} from "./chart-constants";
import getLatestReasonableChartWindowEnd from "./get-latest-reasonable-chart-window-end";
import {
    getLabMIWindowedData,
    getPredictionWindowedData
} from "./get-windowed-data";
import composeAndShiftAllData from "./compose-and-shift-all-data";
import { composeChartDataPoint } from "./compose-chart-datapoint";
import { fillMissingPredictions } from "./fill-missing-predictions";
import { generateWindowedTimeSlots } from "./get-windowed-slots";

interface ChartDataEngineProps {
    /** Rheometer Service that engine will be consuming. */
    rheometerService: RheometerService;
    /** Site parameter for service */
    site: string;
    /** Extruder parameter for service */
    extruder: string;
    /** Max range of the chart */
    maxChartRange: number;
    /** Min range of the chart */
    minChartRange: number;

    /** Handlers and Callbacks */
    onUpdateChartState: (state: ChartState) => void;
    onHandleException: (exception: ChartEngineException) => void;
    onHandleLogging: (
        message: string,
        logLevel?: "ERROR" | "DEBUG" | "WARN" | "INFO"
    ) => void;
}

export class ChartDataEngine {
    private readonly rheometerService: RheometerService;
    private readonly site: string;
    private readonly extruder: string;
    private readonly maxChartRange: number;
    private readonly minChartRange: number;
    private readonly onUpdateChartState: (state: ChartState) => void;
    private readonly onHandleException: (
        exception: ChartEngineException
    ) => void;
    private readonly onHandleLogging: (
        message: string,
        logLevel?: "ERROR" | "DEBUG" | "WARN" | "INFO"
    ) => void;

    /** don't use directly * */
    private _apiPredictionData: PredictionDataPoint[] = [];
    private _apiLabMIData: LabMIDataPoint[] = [];
    private _chartPredictionData: PredictionDataPoint[] = [];
    private _chartLabMIData: LabMIDataPoint[] = [];
    private _paused = false;
    private _isSubscribedToUpdates = false;
    private _currentResinName = "";
    private _currentModelName = "";
    private _currentSupportedResins: string[] = [];
    private _hasScoringMessages = false;
    private _currentRunId = "";
    private _currentScoringMessages: Message[] = [];
    private _lastPredictedPoint: PredictionDataPoint | undefined = undefined;
    private _chartWindowEndDate: Date = new Date(); // This will be set in start
    private _chartRange = 12;
    private _failedFetchAttempts = 0;
    private _fetchRetryInterval: ReturnType<typeof setTimeout> | null = null;
    private _queuedAction: ChartActions | null = null;
    private _engineRequiresRestart = false;

    // Private members with logging. Events back up are provided as well.
    private get apiPredictionData() {
        return this._apiPredictionData;
    }
    private set apiPredictionData(value: PredictionDataPoint[]) {
        this._apiPredictionData = value;
    }
    private get apiLabMIData() {
        return this._apiLabMIData;
    }
    private set apiLabMIData(value: LabMIDataPoint[]) {
        this._apiLabMIData = value;
    }
    private get chartPredictionData() {
        return this._chartPredictionData;
    }
    private set chartPredictionData(value: PredictionDataPoint[]) {
        this._chartPredictionData = value;
        this.onHandleLogging(
            `Set chartPredictionData. Data Points Known: ${value.length}`
        );
    }
    private get chartLabMIData() {
        return this._chartLabMIData;
    }
    private set chartLabMIData(value: LabMIDataPoint[]) {
        this._chartLabMIData = value;
        this.onHandleLogging(
            `Set chartLabMIData. Data Points Known: ${value.length}`
        );
    }
    private get chartWindowEnd() {
        return this._chartWindowEndDate;
    }
    private set chartWindowEnd(value: Date) {
        if (this._chartWindowEndDate.getTime() !== value.getTime()) {
            this._chartWindowEndDate = value;
            this.onHandleLogging(`Set chartWindowEnd=${JSON.stringify(value)}`);
        }
    }
    private get chartWindowStart() {
        return addHours(this.chartWindowEnd, -Math.abs(this.chartRange));
    }

    /** Is the chart data paused? */
    private get paused(): boolean {
        return this._paused;
    }
    private set paused(value: boolean) {
        if (value !== this._paused) {
            this._paused = value;
            this.onHandleLogging(`Set paused=${value}`);
        }
    }

    /** Is the chart subscribed to updates? */
    private get isSubscribedToUpdates(): boolean {
        return this._isSubscribedToUpdates;
    }
    private set isSubscribedToUpdates(value: boolean) {
        if (value !== this._isSubscribedToUpdates) {
            this._isSubscribedToUpdates = value;
            this.onHandleLogging(`Set isSubscribedToUpdates=${value}`);
        }
    }

    /** Most recent resin name */
    private get currentResinName(): string {
        return this._currentResinName;
    }
    private set currentResinName(value: string) {
        if (value !== this._currentResinName) {
            this._currentResinName = value;
            this.onHandleLogging(`Set currentResinName=${value}`);
        }
    }

    /** Most recent model name */
    private get currentModelName(): string {
        return this._currentModelName;
    }
    private set currentModelName(value: string) {
        if (value !== this._currentModelName) {
            this._currentModelName = value;
            this.onHandleLogging(`Set currentModelName=${value}`);
            this.getModelDataAsync(value);
        }
    }

    /** Most recent model name */
    private get currentSupportedResins(): string[] {
        return this._currentSupportedResins;
    }
    private set currentSupportedResins(supportedResins: string[]) {
        if (!equal(supportedResins, this.currentSupportedResins)) {
            this._currentSupportedResins = supportedResins;
            this.onHandleLogging(
                `Set currentSupportedResins=${supportedResins}`
            );
        }
    }

    /** Is there avilable Scoring Messages */
    private get hasScoringMessages(): boolean {
        return this._hasScoringMessages;
    }
    private set hasScoringMessages(hasMessages: boolean) {
        this._hasScoringMessages = hasMessages;
    }

    /**  Most recent Forecast Run Id  */
    private get currentRunId(): string {
        return this._currentRunId;
    }
    private set currentRunId(runId: string) {
        if (!equal(runId, this.currentRunId) && this.hasScoringMessages) {
            this._currentRunId = runId;
            this.onHandleLogging(`Set currentRunId=${runId}`);
            this.getScoringMessagesAsync(runId);
        }
    }

    /** Most recent scoring messages */
    private get currentScoringMessages(): Message[] {
        return this._currentScoringMessages;
    }
    private set currentScoringMessages(messages: Message[]) {
        if (!equal(messages, this.currentScoringMessages)) {
            this._currentScoringMessages = messages;
            this.onHandleLogging(`Set currentScoringMessages=${messages}`);
        }
    }

    /** Last Available Predicted Data Point */
    private get lastPredictedPoint(): PredictionDataPoint | undefined {
        return this._lastPredictedPoint;
    }
    private set lastPredictedPoint(value: PredictionDataPoint | undefined) {
        this._lastPredictedPoint = value;
    }

    /** Chart Time Range */
    private get chartRange(): number {
        return this._chartRange;
    }
    private set chartRange(value: number) {
        this._chartRange = value;
        this.onHandleLogging(`Set chartRange=${value}`);
    }

    /** Chart Time Range */
    private get queuedAction(): ChartActions | null {
        return this._queuedAction;
    }
    private set queuedAction(action: ChartActions | null) {
        this._queuedAction = action;
        this.onHandleLogging(`Set queuedAction=${action}`);
        this.updateChartState();
    }

    private get failedFetchAttempts() {
        return this._failedFetchAttempts;
    }
    private set failedFetchAttempts(value: number) {
        this._failedFetchAttempts = value;
    }

    private get fetchRetryInterval() {
        return this._fetchRetryInterval;
    }
    private set fetchRetryInterval(
        value: ReturnType<typeof setTimeout> | null
    ) {
        this._fetchRetryInterval = value;
    }

    private get engineRequiresRestart() {
        return this._engineRequiresRestart;
    }
    private set engineRequiresRestart(value: boolean) {
        this._engineRequiresRestart = value;
    }

    constructor({
        rheometerService,
        site,
        extruder,
        maxChartRange,
        minChartRange,
        onUpdateChartState,
        onHandleException,
        onHandleLogging
    }: ChartDataEngineProps) {
        // Service and Parameters
        this.rheometerService = rheometerService;
        this.site = site;
        this.extruder = extruder;
        this.maxChartRange = maxChartRange;
        this.minChartRange = minChartRange;

        // Handlers and callbacks
        this.onUpdateChartState = onUpdateChartState;
        this.onHandleException = onHandleException;
        this.onHandleLogging = onHandleLogging;

        // exposed callbacks
        this.onReceiveNewPrediction = this.onReceiveNewPrediction.bind(this);
        this.onReceiveNewLabMeltIndex =
            this.onReceiveNewLabMeltIndex.bind(this);
        this.onActionAsync = this.onActionAsync.bind(this);
    }

    /** Start engine and set up chart ranges */
    public async startAsync(): Promise<void> {
        // Should start on most recent 5th Minute so if now is 10:27, should window starting chart to end with 10:25 point.
        this.chartWindowEnd = getLatestReasonableChartWindowEnd(getCurrentDate);
        this.queuedAction = ChartActions.Play;
        this.onHandleLogging(
            `Init Window: ${JSON.stringify(
                this.chartWindowStart
            )} - ${JSON.stringify(this.chartWindowEnd)}`
        );
        this.subscribe();
        this.fetchWindowDataWithRetryAsync();
    }

    /** Stop sending chart data */
    public stop(): void {
        this._failedFetchAttempts = 0;
        this._fetchRetryInterval = null;
        this.queuedAction = null;
        this.unsubscribe();
    }

    /** Action Callback */
    public async onActionAsync(action: ChartActions): Promise<void> {
        this.queuedAction = action;
        this.chartWindowEnd = nextForcedEndDate[action](this.chartWindowEnd);
        this.chartRange = nextChartRangeState[action](
            this.chartRange,
            this.maxChartRange,
            this.minChartRange,
            action
        );
        this.paused = nextPauseState[action](this.paused);
        this.possibleSubscriptionChange();
        this.fetchWindowDataWithRetryAsync();
    }

    private async fetchWindowDataWithRetryAsync() {
        // Fetch Data
        const { data: predictionData, error } =
            await this.getPredictionDataAsync(
                addHours(this.chartWindowStart, -1),
                this.chartWindowEnd
            ); // Hack Get an hour extra of data.
        const { data: labData } = await this.getLabMIDataAsync(
            addHours(this.chartWindowStart, -1),
            this.chartWindowEnd
        ); // Hack Get an hour extra of data.

        if (error) {
            if (this.failedFetchAttempts >= 5) {
                this.engineRequiresRestart = true;
                this.queuedAction = null;
                clearInterval(
                    this.fetchRetryInterval as ReturnType<typeof setTimeout>
                );
                this.fetchRetryInterval = null;
                this.updateChartState();
                return;
            }
            this.failedFetchAttempts += 1;
            const thirtySecondsInMS = 30 * 1000;
            this.fetchRetryInterval = setTimeout(
                () => this.fetchWindowDataWithRetryAsync(),
                thirtySecondsInMS * this._failedFetchAttempts
            );
            this.updateChartState();
            return;
        }
        this.engineRequiresRestart = false;
        this.failedFetchAttempts = 0;
        this.fetchRetryInterval = null;
        this.apiPredictionData = predictionData as PredictionDataPoint[];
        this.apiLabMIData = (labData && labData) || [];
        this.possibleChartUpdate();
    }

    /** Called explicitly after chart range and data has been fetched to evaluate updating the chart */
    private possibleChartUpdate() {
        // Cases:
        // 1) Initial range has been established.
        // 2) Chart is updating normally and this got called after receiving a new data point.
        // 3) Chart is paused and the window end date has been changed via an action.
        // 4) Chart range has been updated.

        // Generate TimeSlots
        const newWindowedChartSlots = generateWindowedTimeSlots(
            this.chartWindowStart,
            this.chartWindowEnd
        );

        // Fill In Data into TimeSlots
        const windowPredictionChartData = getPredictionWindowedData(
            this.apiPredictionData,
            newWindowedChartSlots
        );

        const windowLabChartData = getLabMIWindowedData(
            this.apiLabMIData,
            newWindowedChartSlots
        );

        if (!equal(windowPredictionChartData, this.chartPredictionData)) {
            this.chartPredictionData = windowPredictionChartData;
            this.getLatestValidPrediction();
            this.updateChartState();
        }

        if (!equal(windowLabChartData, this.chartLabMIData)) {
            this.chartLabMIData = windowLabChartData;
            this.updateChartState();
        }

        this.queuedAction = null;
    }

    private possibleSubscriptionChange() {
        if (this.isSubscribedToUpdates && this.paused) {
            this.unsubscribe();
        } else if (!this.isSubscribedToUpdates && !this.paused) {
            this.subscribe();
        }
    }

    // Service Subscription & Unsubscription
    private subscribe() {
        this.rheometerService.SubscribeToUpdates(
            this.site,
            this.extruder,
            this.onReceiveNewPrediction,
            this.onReceiveNewLabMeltIndex
        );
        this.isSubscribedToUpdates = true;
    }
    private unsubscribe() {
        this.rheometerService.UnsubscribeFromUpdates(
            this.site,
            this.extruder,
            this.onReceiveNewPrediction,
            this.onReceiveNewLabMeltIndex
        );
        this.isSubscribedToUpdates = false;
    }

    private async getPredictionDataAsync(
        start: Date,
        end: Date
    ): Promise<ApiResponse<PredictionDataPoint[]>> {
        const { data, error } = await this.rheometerService.GetHistoryAsync(
            this.site,
            this.extruder,
            start,
            end
        );
        if (data) {
            const filledData = fillMissingPredictions(data);
            return {
                data: filledData
            };
        }
        return {
            error
        };
    }

    private async getLabMIDataAsync(
        start: Date,
        end: Date
    ): Promise<ApiResponse<LabMIDataPoint[]>> {
        const { data, error } =
            await this.rheometerService.GetLabMeltIndexAsync(
                this.site,
                this.extruder,
                start,
                end
            );
        if (data) {
            return {
                data
            };
        }
        this.onHandleException({
            message: error,
            severity: "caution",
            dismissable: true,
            autoDismiss: true,
            autoDismissDelay: 5000
        });
        return {
            error
        };
    }

    private async getModelDataAsync(model: string) {
        const { data, error } = await this.rheometerService.GetModelAsync(
            model
        );
        if (error) {
            this.onHandleException({
                message: error,
                severity: "danger",
                dismissable: true,
                autoDismiss: true,
                autoDismissDelay: 5000
            });
        }
        if (data) {
            const { resinsSupported } = data;
            this.currentSupportedResins = resinsSupported;
            this.updateChartState();
        }
    }

    private async getScoringMessagesAsync(runId: string) {
        const { data, error } = await this.rheometerService.GetScoringMessages(
            runId
        );
        if (error) {
            this.onHandleException({
                message: error,
                severity: "danger",
                dismissable: true,
                autoDismiss: true,
                autoDismissDelay: 5000
            });
        }
        if (data) {
            const { messages } = data;
            this.currentScoringMessages = messages;
            this.updateChartState();
        }
    }

    /** When new prediction data is evented from the Rheometer Service */
    public onReceiveNewPrediction(response: ApiResponse<DataPoint>): void {
        const { data } = response;

        if (!this.isSubscribedToUpdates) {
            return;
        }

        const lastPrediction = this.lastPredictedPoint as PredictionDataPoint;

        const chartDataPoint = composeChartDataPoint(
            data as DataPoint,
            (lastPrediction && lastPrediction.dataPoint.predicted) || null
        );

        if (chartDataPoint?.hasMissingPrediction) {
            this.onHandleException({
                statusText: "No Prediction!",
                message: "System is currently unable to get prediction.",
                severity: "caution",
                autoDismiss: true,
                autoDismissDelay: 10000
            });
        }

        // Assumption: Incoming dataPoint occurs AFTER ALL entries in chartPredictionData
        if (chartDataPoint) {
            const shiftedData = composeAndShiftAllData(
                this.apiPredictionData,
                chartDataPoint
            ) as PredictionDataPoint[];
            if (!equal(shiftedData, this.apiPredictionData)) {
                this.chartWindowEnd = chartDataPoint.timeStamp;
                this.apiPredictionData = shiftedData;
                this.possibleChartUpdate();
            }
        }
    }

    /** When new data is evented from the Rheometer Service */
    public onReceiveNewLabMeltIndex(
        response: ApiResponse<LabMIDataPoint>
    ): void {
        // Assumption: Incoming dataPoint occurs AFTER ALL entries in apiLabMIData
        const { data: incomingDataPoint } = response;

        if (!this.isSubscribedToUpdates) {
            return;
        }
        if (incomingDataPoint) {
            const shiftedData = composeAndShiftAllData(
                this.apiLabMIData,
                incomingDataPoint
            ) as LabMIDataPoint[];
            if (!equal(shiftedData, this.apiLabMIData)) {
                this.chartWindowEnd = incomingDataPoint.timeStamp;
                this.apiLabMIData = shiftedData;
                this.possibleChartUpdate();
            }
        }
    }

    private getLatestValidPrediction() {
        const validPredictions = this.chartPredictionData.filter(
            ({ dataPoint }) => dataPoint
        );
        const lastValidPrediction =
            validPredictions[validPredictions.length - 1];

        if (lastValidPrediction && lastValidPrediction.dataPoint) {
            const { dataPoint } = lastValidPrediction;
            this.currentResinName = dataPoint.resinName;
            this.currentModelName = dataPoint.model;
            this.hasScoringMessages = dataPoint.hasMessages;
            this.currentRunId = dataPoint.runId;
            this.lastPredictedPoint = lastValidPrediction;
            this.updateChartState();
        }
    }

    private updateChartState() {
        this.onUpdateChartState({
            predictionData: this.chartPredictionData,
            labData: this.chartLabMIData,
            isPaused: this.paused,
            chartRange: this.chartRange,
            currentResinName: this.currentResinName,
            currentSupportedResins: this.currentSupportedResins,
            queuedAction: this.queuedAction,
            currentScoringMessages: this.currentScoringMessages,
            engineRequiresRestart: this.engineRequiresRestart,
            failedFetchAttempts: this.failedFetchAttempts
        });
    }
}
