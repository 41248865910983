import React from "react";
import { faPlay, faPause } from "@fortawesome/pro-solid-svg-icons";
import "../icon-button/style.scss";
import { ChartActions } from "../chart/utils/chart-constants";
import { Chart_Elements } from "../chart/elements";
import { IconButton } from "../icon-button";

export interface PlayButtonProps {
    className?: string;
    onClick: (action: ChartActions) => void;
    isPaused?: boolean;
    chartTestId: string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

export function PlayButton({
    className,
    onClick,
    isPaused,
    chartTestId,
    isDisabled
}: PlayButtonProps): JSX.Element {
    function handleToggle() {
        onClick(isPaused ? ChartActions.Play : ChartActions.Pause);
    }

    return (
        <IconButton
            className={className}
            onClick={handleToggle}
            ariaLabel={isPaused ? "Play" : "Pause"}
            icon={isPaused ? faPlay : faPause}
            testId={`${chartTestId}-${Chart_Elements.Play_Pause_Button}`}
            isDisabled={isDisabled}
        />
    );
}
