import { LogEvent } from "@cpchem/logging";

export const CUSTOM_EVENT_TYPE = "DPO_CustomEvent";

export function createCustomEvent(
    name: string,
    properties?: Record<string, unknown>
): LogEvent {
    const rawContents = {
        name,
        properties
    };

    return {
        type: CUSTOM_EVENT_TYPE,
        contents: JSON.stringify(rawContents)
    };
}
