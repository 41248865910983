import { AuthorizedExtruder, Chart } from "./use-multi-chart";

export function updateActiveExtruders(
    authorizedExtruders: AuthorizedExtruder[],
    existingCharts: Chart[]
): AuthorizedExtruder[] {
    const activeExtruders = existingCharts.map((chart) => chart.extruder);
    const updatedExtruders = authorizedExtruders.map((extruder) => {
        return {
            ...extruder,
            isActive: activeExtruders.includes(extruder.name)
        };
    });
    return updatedExtruders;
}
