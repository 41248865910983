import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Grid, Container } from "@cpchem/covalence-ui";
import { trackEvent } from "@cpchem/logging";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import ChartsContainer from "./components/charts-container";
import { Site } from "@services";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";

function RheometerPage(): JSX.Element {
    const [userLocation, setUserLocation] = useState<string>("");

    function onLocationChanged(locations: Site[]) {
        const isSingleLocation = locations.length === 1;

        if (isSingleLocation) {
            const location = `${capitalizeFirstLetter(
                locations[0].displayName
            )} Plant`;
            setUserLocation(location);
            return;
        }

        setUserLocation("All Plants");
    }

    useEffect(() => {
        trackEvent(createPageViewEvent("Rheometer Page"));
    }, []);

    return (
        <Grid className="rheometer-page">
            <Container className="container" isFluid>
                <div className="page-header">
                    <h1 className="page-title">Melt Index Chart</h1>
                    <p role="doc-subtitle" className="page-description">
                        Live Analytic Tracking of Product Melt Index Data
                    </p>
                    <div className="location">
                        <p>{userLocation}</p>
                    </div>
                </div>
                <ChartsContainer
                    className="charts-container"
                    onLocationChanged={onLocationChanged}
                />
            </Container>
        </Grid>
    );
}

export default RheometerPage;
