import {
    FetchOptionCredential,
    FetchOptionMode,
    FetchOptions
} from "@services";

export interface FetchinterceptorService {
    getFetchOptionsAsync(
        base: string,
        scopes: string[],
        method?: string,
        mode?: FetchOptionMode,
        credentials?: FetchOptionCredential
    ): Promise<FetchOptions>;
}

export const FetchServiceKey = "Fetchinterceptor";
