import React from "react";
import { IconButton } from "../../icon-button";
import { ChartActions } from "../utils/chart-constants";
import {
    faFastBackward,
    faFastForward,
    faMinus,
    faPlus
} from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { PlayButton } from "../../play-button";
import { ConnectLineButton } from "../../connect-line-button";

import { Chart_Elements } from "../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Tooltip } from "@cpchem/covalence-ui";

interface ChartActionsProps {
    onActionButtonClick: (action: ChartActions) => void;
    paused: boolean;
    chartTestId: string;
    isMultiChart: boolean;
    handleDismissChart: () => void;
    queuedAction: ChartActions | null;
    handleConnectLabMILine: () => void;
    isLabMIPointsConnected: boolean;
}
export default function ResinHeader({
    onActionButtonClick,
    paused,
    chartTestId,
    isMultiChart,
    handleDismissChart,
    queuedAction,
    handleConnectLabMILine,
    isLabMIPointsConnected
}: ChartActionsProps): JSX.Element | null {
    const { historyButtons, chartExtensionButtons } = useFlags();
    const isActionsDisabled = !!queuedAction;
    return (
        <div className="actions">
            {historyButtons && (
                <>
                    <Tooltip
                        content="Move Backwards 60 Minutes"
                        side="top"
                        variant="alt"
                    >
                        <IconButton
                            onClick={() =>
                                onActionButtonClick(ChartActions.FastBackward)
                            }
                            icon={faFastBackward}
                            testId={`${chartTestId}-${Chart_Elements.Fast_Backwards_Button}`}
                            ariaLabel="Move Backwards 60 Minutes"
                            isDisabled={isActionsDisabled}
                        />
                    </Tooltip>
                    <Tooltip
                        content={paused ? "Play" : "Pause"}
                        side="top"
                        variant="alt"
                    >
                        <PlayButton
                            onClick={onActionButtonClick}
                            isPaused={paused}
                            chartTestId={chartTestId}
                            isLoading={queuedAction === ChartActions.Play}
                            isDisabled={isActionsDisabled}
                        />
                    </Tooltip>
                    <Tooltip
                        content="Move Forwards 60 Minutes"
                        side="top"
                        variant="alt"
                    >
                        <IconButton
                            onClick={() =>
                                onActionButtonClick(ChartActions.FastForward)
                            }
                            icon={faFastForward}
                            ariaLabel="Move Forwards 60 Minutes"
                            isDisabled={isActionsDisabled}
                            testId={`${chartTestId}-${Chart_Elements.Fast_Forwards_Button}`}
                        />
                    </Tooltip>

                    <div className="button-divider" />
                </>
            )}

            {chartExtensionButtons && (
                <>
                    <Tooltip
                        content="Extend Chart Time Range"
                        side="top"
                        variant="alt"
                    >
                        <IconButton
                            onClick={() =>
                                onActionButtonClick(
                                    ChartActions.ExtendChartTimeRange
                                )
                            }
                            icon={faPlus}
                            ariaLabel="Extend Chart Time Range By 2 Hours"
                            isDisabled={isActionsDisabled}
                            testId={`${chartTestId}-${Chart_Elements.Extend_Time_Button}`}
                        />
                    </Tooltip>
                    <Tooltip
                        content="Shrink Chart Time Range"
                        side="top"
                        variant="alt"
                    >
                        <IconButton
                            onClick={() =>
                                onActionButtonClick(
                                    ChartActions.ShrinkChartTimeRange
                                )
                            }
                            icon={faMinus}
                            ariaLabel="Shrink Chart Time Range By 2 Hours"
                            isDisabled={isActionsDisabled}
                            testId={`${chartTestId}-${Chart_Elements.Shrink_Time_Button}`}
                        />
                    </Tooltip>

                    <div className="button-divider" />
                </>
            )}
            <Tooltip
                content={
                    isLabMIPointsConnected
                        ? "Disconnects Lab Data Points"
                        : "Connects Lab Data Points"
                }
                side="top"
                variant="alt"
            >
                <ConnectLineButton
                    onClick={handleConnectLabMILine}
                    isConnected={isLabMIPointsConnected}
                    chartTestId={chartTestId}
                />
            </Tooltip>

            {isMultiChart && (
                <Tooltip content="Close Chart" side="top" variant="alt">
                    <div
                        className="close-button"
                        onClick={handleDismissChart}
                        data-testid={`${chartTestId}-${Chart_Elements.Close_Button}`}
                    >
                        <FontAwesomeIcon
                            icon={faTimes as IconProp}
                            className="icon"
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
}
