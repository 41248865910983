import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faExclamationTriangle,
    IconDefinition
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Chart_Elements } from "../elements";

interface ResinHeaderProps {
    currentResinName: string;
    currentSupportedResins: string[];
    handleOpenResinsModal: () => void;
    chartTestId?: string;
}
export default function ResinHeader({
    currentResinName,
    currentSupportedResins,
    handleOpenResinsModal,
    chartTestId
}: ResinHeaderProps): JSX.Element | null {
    const currentResinIsSupported = currentSupportedResins.length !== 0;

    if (!currentResinName) {
        return (
            <div
                data-testid={`${chartTestId}-${Chart_Elements.Resin_Header}`}
                className="resin no-data"
            >
                <div className="details">
                    <h3 className="name">Resin Name Is Not Available</h3>
                    <FontAwesomeIcon
                        className="icon"
                        icon={faExclamationTriangle as IconProp}
                    />
                </div>
            </div>
        );
    }

    if (!currentResinIsSupported) {
        return (
            <div
                data-testid={`${chartTestId}-${Chart_Elements.Resin_Header}`}
                className="resin no-data"
            >
                <div className="details">
                    <h3 className="name">
                        Model Not Trained for Current Resin
                    </h3>
                    <FontAwesomeIcon
                        className="icon"
                        icon={faExclamationTriangle as IconProp}
                    />
                </div>
            </div>
        );
    }

    const { resinClassName, resinStatusText, resinIcon } = determineResinStatus(
        currentResinName,
        currentSupportedResins
    );

    return (
        <div
            data-testid={`${chartTestId}-${Chart_Elements.Resin_Header}`}
            className={resinClassName}
        >
            <div className="details" onClick={handleOpenResinsModal}>
                <h3 className="name">Resin: {currentResinName}</h3>
                {resinStatusText && (
                    <h3 className="status">{resinStatusText}</h3>
                )}
                <FontAwesomeIcon
                    className="icon"
                    icon={resinIcon as IconProp}
                />
            </div>
        </div>
    );
}

const determineResinStatus = (
    currentResinName: string,
    currentSupportedResins: string[]
): {
    resinClassName: string;
    resinStatusText: string;
    resinIcon: IconDefinition;
} => {
    const currentResinIsSupported =
        currentSupportedResins.includes(currentResinName);

    if (!currentResinIsSupported) {
        return {
            resinClassName: "resin unsupported",
            resinStatusText: "Digital Rheometer not trained for current resin",
            resinIcon: faExclamationTriangle
        };
    }

    return {
        resinClassName: "resin",
        resinStatusText: "",
        resinIcon: faInfoCircle
    };
};
