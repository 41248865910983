import { AspectRatio } from "@components/charts/line-chart";

export function getAspectRatio(
    isMultiChart: boolean,
    matchesMedia: boolean
): AspectRatio {
    let aspectRatio = matchesMedia ? AspectRatio["3:2"] : AspectRatio["16:7"];

    if (isMultiChart) {
        aspectRatio = matchesMedia ? AspectRatio["3:1"] : AspectRatio["16:9"];
    }

    return aspectRatio;
}
