import { AuthorizedExtruder, Chart } from "./use-multi-chart";

export function initializeDefaultChartValues(
    authorizedExtruders: AuthorizedExtruder[],
    charts: Chart[]
): Chart[] {
    if (authorizedExtruders.length === 0) return charts;

    const defautlCharts = charts.map((chart, index) => {
        const defaultExtruder = authorizedExtruders[index];
        return {
            ...chart,
            chartName: defaultExtruder.displayName,
            extruder: defaultExtruder.name,
            site: defaultExtruder.siteCode,
            isActive: true
        };
    });

    return defautlCharts;
}
