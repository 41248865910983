export function getNearestFifthFloor(input: number): number {
    return Math.floor(input / 5) * 5;
}

export default function getLatestReasonableChartWindowEnd(
    getCurrentDate: () => Date
): Date {
    const now = getCurrentDate();
    now.setMinutes(getNearestFifthFloor(now.getMinutes()));
    return now;
}
