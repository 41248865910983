import React from "react";
import { AuthorizedExtruder } from "../../hooks/use-multi-chart";
import { Extruder_Selector_Elements } from "./elements";
import "./style.scss";

interface ExtruderSelectorProps {
    chartIndex: number;
    authorizedExtruders: AuthorizedExtruder[];
    onRequestSelectExtruder: (
        chartIndex: number,
        selectedExtruder: string
    ) => void;
    testId: string;
}

function ExtruderSelector({
    chartIndex,
    authorizedExtruders,
    onRequestSelectExtruder,
    testId: selectorTestId
}: ExtruderSelectorProps): JSX.Element {
    function handleExtruderSelection(extruderSelection: string) {
        onRequestSelectExtruder(chartIndex, extruderSelection);
    }
    return (
        <div className="extruder-selector" data-testid={selectorTestId}>
            <div className="header">
                <div className="information">
                    <div className="title">
                        <h2>Extruder Board Selection</h2>
                    </div>
                    <div className="description">
                        <h3>Please select a train</h3>
                    </div>
                </div>
            </div>

            <div className="container">
                <table
                    data-testid={`${selectorTestId}-${Extruder_Selector_Elements.Extruder_Selection_Table}`}
                >
                    <thead>
                        <tr>
                            <th>Extruder Train</th>
                            <th>Site</th>
                            <th>Selection</th>
                        </tr>
                    </thead>
                    <tbody>
                        {authorizedExtruders
                            .filter((extruder) => !extruder.isActive)
                            .map((extruder) => {
                                return (
                                    <tr
                                        key={extruder.name}
                                        data-testid={`${Extruder_Selector_Elements.Extruder_Selection_Row}-${extruder.name}`}
                                    >
                                        <td>{extruder.displayName}</td>
                                        <td>{extruder.siteName}</td>
                                        <td>
                                            <button
                                                onClick={() =>
                                                    handleExtruderSelection(
                                                        extruder.name
                                                    )
                                                }
                                                className="selection-button"
                                                tabIndex={0}
                                                data-testid={`${Extruder_Selector_Elements.Extruder_Selection_Button}-${extruder.name}`}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ExtruderSelector;
