import React, { useEffect } from "react";
import "./styles.scss";
import { Grid, Container, Card } from "@cpchem/covalence-ui";
import { trackEvent } from "@cpchem/logging";
import { createPageViewEvent } from "@utilities/log/page-view-event";
import { documentation, sections } from "./constants";
import { useScrollSpy } from "./hooks/use-scrollspy";
import { NavItem } from "./components/nav-item";

function DocumentationPage(): JSX.Element {
    useEffect(() => {
        trackEvent(createPageViewEvent("Documentation Page"));
    }, []);

    const { spyItems } = useScrollSpy({
        entries: sections
    });

    return (
        <Grid className="documentation-page">
            <Container className="container" isFluid>
                <div className="page-header">
                    <h1 className="page-title">Digital Rheometer</h1>
                    <p role="doc-subtitle" className="page-description">
                        Live Analytic Tracking of Product Melt Index Data
                    </p>
                </div>
                <div className="content">
                    <div
                        className="documentation"
                        data-spy="scroll"
                        data-target="#scrollspy"
                        data-offset="20"
                    >
                        {documentation}
                    </div>
                    <div className="information-legend">
                        <Card className="card" gap>
                            <h2>What this document covers</h2>
                            <nav id="scrollspy">
                                <ul>
                                    {spyItems &&
                                        spyItems
                                            .filter((item) => !!item)
                                            .map((item) => {
                                                return (
                                                    <NavItem
                                                        key={item.entry.id}
                                                        item={item}
                                                    />
                                                );
                                            })}
                                </ul>
                            </nav>
                        </Card>
                    </div>
                </div>
            </Container>
        </Grid>
    );
}

export default DocumentationPage;
