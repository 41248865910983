import React from "react";
import { useTheme, Button } from "@cpchem/covalence-ui";
import DarkSpinner from "../../../../../assets/dark-spinner-50x50.png";
import LightSpinner from "../../../../../assets/light-spinner-50x50.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Chart_Elements } from "../elements";

interface ChartOverlayProps {
    isLoading: boolean;
    hasError: boolean;
    failedFetchAttempts: number;
    chartTestId: string;
}

export function ChartOverlay({
    isLoading,
    hasError,
    failedFetchAttempts,
    chartTestId
}: ChartOverlayProps) {
    const { theme } = useTheme();

    const isDarkTheme = theme === "dark";

    function handleRefreshPage() {
        window.location.reload();
    }

    const loadingMessage = failedFetchAttempts
        ? `Error in attempt to fetch data. Automatically retrying in ${
              failedFetchAttempts * 30
          } seconds...`
        : "Data is loading...";

    let testElement = `${chartTestId}-${Chart_Elements.Chart_Overlay}`;

    if (hasError) {
        testElement += "-error";
        return (
            <div className="overlay" data-testid={testElement}>
                <FontAwesomeIcon
                    className="icon"
                    icon={faArrowsRotate as IconProp}
                />
                <p className="message">
                    Maximum Refetch Attempts Exceeded. Please refresh the page
                    or try again later.
                </p>
                <Button
                    onClick={handleRefreshPage}
                    className="button"
                    variant="outline"
                    text="REFRESH"
                    color="primary"
                />
            </div>
        );
    }

    if (isLoading) {
        testElement += "-loading";
        return (
            <div className="overlay" data-testid={testElement}>
                <img
                    alt="Spinner"
                    src={isDarkTheme ? LightSpinner : DarkSpinner}
                    className="rotate"
                />
                <p className="message">{loadingMessage}</p>
            </div>
        );
    }

    return null;
}
