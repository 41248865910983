import React from "react";
import { Card } from "@cpchem/covalence-ui";
import "./styles.scss";

interface SectionProps {
    id?: string;
    className?: string;
    children?: React.ReactChild | React.ReactChild[];
}

export function Section({
    id,
    className,
    children
}: SectionProps): JSX.Element {
    let cn = "section";
    if (className) {
        cn += ` ${className}`;
    }
    return (
        <section id={id} className={cn}>
            <Card className="card" gap>
                {children}
            </Card>
        </section>
    );
}
