export function addHours(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours));
}

export function addMinutes(date: Date, minutes: number): Date {
    return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
}

export function isDate(value: unknown): value is Date {
    return value instanceof Date;
}

const isoRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

export function jsonDateParser(key: string, value: unknown): Date | unknown {
    if (typeof value === "string") {
        const match = isoRegex.exec(value);
        if (match) {
            return new Date(value);
        }
    }
    return value;
}

export function getCurrentDate(): Date {
    return new Date();
}

export function isDateMidnight(value: number): boolean {
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "America/Chicago"
    });
    const dateValue = dateFormatter.format(new Date(value));
    return dateValue.search("12:00 AM") !== -1;
}

export const twelveHourAndMinuteTimeFormat = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
});
