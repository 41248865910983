import React, { useState, useEffect } from "react";
import { faBrowser, faColumns } from "@fortawesome/pro-light-svg-icons";
import { ChartLayoutButton } from "../../components/chart-layout-button";
import { ChartLayout, useMultiChart } from "../../hooks/use-multi-chart";
import { useAuth } from "@hooks";
import ExtruderSelector from "../extruder-selector";
import Chart from "../chart";
import { Charts_Container_Elements } from "./elements";
import SupportedResinsModal from "./supported-resins-modeal";

import "./styles.scss";
import {
    RheometerService,
    RheometerServiceKey,
    ServiceLocator,
    Site
} from "@services";
import { ChartEngineException } from "../chart/utils/chart-constants";
import {
    useAlertBanner,
    useNotifications,
    useToast
} from "@cpchem/covalence-ui";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { capitalizeFirstLetter } from "@utilities/capitalize-first-letter";
interface SelectedChart {
    extruder: string;
    supportedResins: string[];
}
interface ChartsContainerProps {
    className?: string;
    onLocationChanged: (locations: Site[]) => void;
}

export default function ChartsContainer({
    className,
    onLocationChanged
}: ChartsContainerProps): JSX.Element {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState<SelectedChart>({
        extruder: "",
        supportedResins: []
    });

    const { currentAccount } = useAuth();
    const { createAlertBanner } = useAlertBanner();
    const { createToast } = useToast();
    const { createNotification } = useNotifications();
    const ldClient = useLDClient();

    const rheometerService =
        ServiceLocator.get<RheometerService>(RheometerServiceKey);

    function handleException(exception: ChartEngineException) {
        if (exception.severity === "danger") {
            createAlertBanner({
                severity: exception.severity,
                message: exception.message,
                autoDismiss: exception.autoDismiss,
                autoDismissDelay: exception.autoDismissDelay
            });
        } else {
            createToast(exception.message, {
                severity: exception.severity
            });
        }
        createNotification({
            severity: exception.severity,
            message: exception.message
        });
    }

    const {
        charts,
        authorizedExtruders,
        chartLayout,
        sites,
        onRequestSelectExtruder,
        onRequestDismiss,
        onRequestLayoutChange
    } = useMultiChart(
        rheometerService,
        currentAccount?.username,
        handleException
    );

    const isMultiChart = chartLayout !== ChartLayout.Single;

    function onRequestResinModalOpen(
        extruder: string,
        supportedResins: string[]
    ) {
        setModalInfo({ extruder, supportedResins });
        handleToggleModal();
    }

    function handleToggleModal() {
        setModalOpen(!isModalOpen);
    }

    const availableLocationsAndExtruders =
        sites &&
        authorizedExtruders &&
        sites.length !== 0 &&
        authorizedExtruders.length !== 0;

    useEffect(() => {
        if (ldClient && availableLocationsAndExtruders && currentAccount) {
            ldClient.identify({
                key: currentAccount.username,
                kind: `user`,
                email: currentAccount.username,
                custom: {
                    authorizedLocations: sites.map((site) =>
                        capitalizeFirstLetter(site.displayName)
                    ),
                    authorizedExtruders: authorizedExtruders.map((extruder) =>
                        capitalizeFirstLetter(extruder.displayName)
                    ),
                    visibleExtruders: charts.map((chart) =>
                        capitalizeFirstLetter(chart.extruder)
                    ),
                    appVersion: import.meta.env.PACKAGE_VERSION
                }
            });
        }
    }, [
        sites,
        authorizedExtruders,
        charts,
        availableLocationsAndExtruders,
        ldClient,
        currentAccount
    ]);

    useEffect(() => {
        if (availableLocationsAndExtruders) {
            onLocationChanged(sites);
        }
    }, [sites, availableLocationsAndExtruders, onLocationChanged]);

    return (
        <div
            className={className}
            data-testid={Charts_Container_Elements.Charts_Container}
        >
            <div className="charts-layout">
                <ChartLayoutButton
                    icon={faBrowser}
                    onClick={() => onRequestLayoutChange(ChartLayout.Single)}
                    ariaLabel="Display one chart."
                    isActive={chartLayout === ChartLayout.Single}
                    testId={
                        Charts_Container_Elements.Chart_Layout_Single_Button
                    }
                />
                <ChartLayoutButton
                    icon={faColumns}
                    onClick={() => onRequestLayoutChange(ChartLayout.Dual)}
                    ariaLabel="Display two charts."
                    isActive={chartLayout === ChartLayout.Dual}
                    testId={Charts_Container_Elements.Chart_Layout_Dual_Button}
                />
            </div>
            <div className="charts">
                {charts.map((chart, index) => {
                    const { site, extruder, chartName, isActive } = chart;
                    const canRenderChart = site && extruder;

                    if (!isActive) {
                        return null;
                    }

                    if (canRenderChart) {
                        return (
                            <Chart
                                key={chartName}
                                site={site}
                                extruder={extruder}
                                chartName={chartName}
                                isMultiChart={isMultiChart}
                                onRequestDismiss={onRequestDismiss}
                                testId={`${Charts_Container_Elements.Chart_Body}-${index}`}
                                onRequestResinModalOpen={
                                    onRequestResinModalOpen
                                }
                                onRequestSelectExtruder={
                                    onRequestSelectExtruder
                                }
                                chartIndex={index}
                                authorizedExtruders={authorizedExtruders}
                            />
                        );
                    }

                    return (
                        <ExtruderSelector
                            key={chartName || `chart-${index}`}
                            chartIndex={index}
                            onRequestSelectExtruder={onRequestSelectExtruder}
                            authorizedExtruders={authorizedExtruders}
                            testId={`${Charts_Container_Elements.Extruder_Selector}-${index}`}
                        />
                    );
                })}
            </div>
            <SupportedResinsModal
                isOpen={isModalOpen}
                onRequestModalClose={handleToggleModal}
                modalInfo={modalInfo}
            />
        </div>
    );
}
