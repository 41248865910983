import React, { useEffect, useState } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ServiceLocator, AuthServiceKey } from "@services";
import { AuthManager } from "@cpchem/azure-auth";
import {
    clearAppInsightsIdenity,
    identifyAppInsightsUser
} from "@utilities/log/configure-app-insights";
import { AuthContext } from "./context";

export interface AuthProviderProps {
    children: React.ReactNode;
    appInsights: ApplicationInsights | undefined;
}
export function AuthProvider({
    children,
    appInsights
}: AuthProviderProps): JSX.Element {
    const authService = ServiceLocator.get<AuthManager>(AuthServiceKey);
    const [isLoggedIn, setLoggedIn] = useState(authService.isLoggedIn());

    const [currentAccount, setCurrentAccount] = useState(
        authService.getAccount()
    );

    useEffect(() => {
        function handleLoggedIn() {
            setLoggedIn(true);
            setCurrentAccount(authService.getAccount());
        }

        function handleLoggedOut() {
            setLoggedIn(false);
            setCurrentAccount(null);
        }

        authService.subscribe("loggedIn", handleLoggedIn);
        authService.subscribe("loggedOut", handleLoggedOut);

        setLoggedIn(authService.isLoggedIn());
        setCurrentAccount(authService.getAccount());

        return function cleanup() {
            authService.unsubscribe("loggedIn", handleLoggedIn);
            authService.unsubscribe("loggedOut", handleLoggedOut);
        };
    }, [authService]);

    // Logging Identity
    useEffect(() => {
        if (isLoggedIn && currentAccount) {
            const validatedId = currentAccount.username.replace(
                /[,;=| ]+/g,
                "_"
            );
            if (appInsights) {
                identifyAppInsightsUser(appInsights, validatedId);
            }
        }

        return () => {
            if (appInsights) {
                clearAppInsightsIdenity(appInsights);
            }
        };
    }, [isLoggedIn, currentAccount, appInsights]);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                currentAccount,
                login: authService.loginAsync,
                logout: authService.logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
