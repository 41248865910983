import { useCallback, useEffect, useState } from "react";
import { isInView } from "./is-in-view";

export interface Entry {
    id: string;
    name: string;
}

export interface SpyItem {
    inView: boolean;
    element: HTMLElement | null;
    entry: Entry;
}

export interface UseScrollspyProps {
    entries: Entry[];
}
export interface UseScrollspyReturn {
    spyItems: SpyItem[];
}

export function useScrollSpy({
    entries
}: UseScrollspyProps): UseScrollspyReturn {
    const [spyItems, setSpyItems] = useState<SpyItem[]>([]);

    const setDefaultItemsFromEntries = useCallback(() => {
        const items = entries.map((entry) => {
            return {
                inView: false,
                element: null,
                entry
            } as SpyItem;
        });
        setSpyItems(items);
    }, [entries]);

    const spy = useCallback(() => {
        const items = entries.map((entry) => {
            const element = document.getElementById(entry.id);
            if (element) {
                return {
                    inView: isInView(element),
                    element,
                    entry
                } as SpyItem;
            }
            return undefined;
        });

        const filteredSpyItems = items.filter((item) => !!item) as SpyItem[];

        const firstTrueItem = filteredSpyItems.find((item) => item.inView);

        if (firstTrueItem) {
            const update = items.map((item) => {
                return {
                    ...item,
                    inView: item === firstTrueItem
                } as SpyItem;
            });
            setSpyItems(update);
            return;
        }
    }, [entries]);

    useEffect(() => {
        setDefaultItemsFromEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", spy);

        return () => {
            window.removeEventListener("scroll", spy);
        };
    }, [spy]);

    return {
        spyItems
    };
}
