import { addMinutes } from "@utilities/date";

export interface TimeSlot {
    timeStamp: Date;
}

// Generates Time Slots with End Time Included.
export function generateWindowedTimeSlots(
    windowStartDate: Date,
    windowEndDate: Date,
    stepInMinutes = 5
): TimeSlot[] {
    const timeSlots: TimeSlot[] = [];
    let currentTimestamp = windowStartDate;
    while (currentTimestamp <= windowEndDate) {
        timeSlots.push({
            timeStamp: currentTimestamp
        });
        currentTimestamp = addMinutes(currentTimestamp, stepInMinutes);
    }

    return timeSlots;
}
