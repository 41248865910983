import {
    useState,
    useEffect,
    useCallback,
    useRef,
    MutableRefObject
} from "react";
import {
    RheometerService,
    RheometerServiceKey,
    ServiceLocator
} from "@services";
import {
    ChartState,
    ChartActions,
    ChartEngineException,
    EDPSeverityTranslation,
    initialChartState
} from "./chart-constants";
import { ChartDataEngine } from "./chart-data-engine";

import { useAuth } from "@hooks";

interface UseChartProps {
    site: string;
    extruder: string;
    maxChartRange: number;
    minChartRange: number;
    onHandleException: (exception: ChartEngineException) => void;
    onHandleLogging: (
        message: string,
        logLevel?: "ERROR" | "DEBUG" | "WARN" | "INFO"
    ) => void;
}

interface UseChartReturn {
    chartState: ChartState;
    onAction: (action: ChartActions) => void;
}

// Very simple hook wrapper around ChartDataEngine
export function useChart({
    // TODO Single Object, Rename ot useChartData, Chart => Board
    site,
    extruder,
    maxChartRange,
    minChartRange,
    onHandleException,
    onHandleLogging
}: UseChartProps): UseChartReturn {
    const engine = useRef<ChartDataEngine | null>(null);
    const { currentAccount } = useAuth();

    const [chartState, setChartState] = useState<ChartState>({
        ...initialChartState
    });

    const onUpdateChartState = useCallback((state: ChartState) => {
        setChartState(state);
    }, []);

    const onAction = useCallback(async (action: ChartActions) => {
        await engine.current?.onActionAsync(action);
    }, []);

    useEffect(() => {
        const { currentScoringMessages } = chartState;
        if (currentScoringMessages && currentScoringMessages.length) {
            const { text, severity } = currentScoringMessages[0];
            const translatedSeverity =
                EDPSeverityTranslation[severity.toLowerCase()];
            onHandleException({
                message: `Forecast message for ${site} - ${extruder}: ${text}`,
                severity: translatedSeverity,
                autoDismiss: true,
                autoDismissDelay: 10000
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartState]);

    useEffect(() => {
        async function startEngineAsync(
            engine: MutableRefObject<ChartDataEngine>
        ) {
            await engine.current.startAsync();
        }
        const rheometerService =
            ServiceLocator.get<RheometerService>(RheometerServiceKey);

        if (currentAccount) {
            engine.current = new ChartDataEngine({
                rheometerService,
                site,
                extruder,
                maxChartRange,
                minChartRange,
                onUpdateChartState,
                onHandleException,
                onHandleLogging
            });
            startEngineAsync(engine as MutableRefObject<ChartDataEngine>);
        }

        return () => {
            engine.current?.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site, extruder, currentAccount]);

    return {
        chartState,
        onAction
    };
}
