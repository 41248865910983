import React, { useCallback, useState } from "react";
import "./style.scss";
import {
    useAlertBanner,
    useNotifications,
    useToast
} from "@cpchem/covalence-ui";
import { Pill } from "@components/pill";
import { LineChart, Line, AxisMode } from "@components/charts/line-chart";
import {
    faArrowToTop,
    faHeartRate,
    faBullseyeArrow,
    faArrowToBottom,
    faFlaskVial
} from "@fortawesome/pro-light-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { useChart } from "./utils/use-chart";
import {
    ChartActions,
    ChartEngineException,
    ChartRangeXTickTranslation
} from "./utils/chart-constants";
import { isDateMidnight, twelveHourAndMinuteTimeFormat } from "@utilities/date";
import { precisionRound } from "@utilities/math";
import tickFactory from "@components/charts/tickFactory";
import FormattedDateIndicator from "./indicator-factories/formatted-date-indicator";
import { isXAxisLeftmostDataPoint } from "./indicator-determinants/is-xaxis-leftmost-datapoint";
import { Chart_Elements } from "./elements";
import { log, LogLevel, trackEvent } from "@cpchem/logging";
import { createCustomEvent } from "@utilities/log/custom-event";
import ResinHeader from "./components/resin-header";
import { getAspectRatio } from "./utils/get-aspect-ratio";
import { useMediaQuery } from "@hooks";
import ChartHeader from "./components/chart-header";
import { AuthorizedExtruder } from "../../hooks/use-multi-chart";
import ChartActionsHeader from "./components/chart-actions";
import { ChartOverlay } from "./components/chart-overlay";
export interface ChartProps {
    site: string;
    extruder: string;
    chartName: string;
    isMultiChart: boolean;
    onRequestDismiss: (boardName: string) => void;
    testId: string;
    onRequestResinModalOpen: (
        extruder: string,
        currentSupportedResins: string[]
    ) => void;
    onRequestSelectExtruder: (
        chartIndex: number,
        selectedExtruder: string
    ) => void;
    authorizedExtruders: AuthorizedExtruder[];
    chartIndex: number;
}

function Chart({
    site,
    extruder,
    chartName,
    isMultiChart,
    onRequestDismiss,
    testId: chartTestId,
    onRequestResinModalOpen,
    onRequestSelectExtruder,
    authorizedExtruders,
    chartIndex
}: ChartProps): JSX.Element {
    let cn = "chart";

    if (isMultiChart) {
        cn += " multi-chart";
    }

    const [isLabMIPointsConnected, setConnectedLabMILine] =
        useState<boolean>(false);

    const isSquareMonitor = useMediaQuery("(max-aspect-ratio: 4/3)");

    const aspectRatio = getAspectRatio(isMultiChart, isSquareMonitor);

    const maxChartRange = 24;
    const minChartRange = 2;

    const { createAlertBanner } = useAlertBanner();
    const { createToast } = useToast();
    const { createNotification } = useNotifications();

    function handleException(exception: ChartEngineException) {
        if (exception.severity === "danger") {
            createAlertBanner({
                severity: exception.severity,
                message: exception.message,
                dismissable: exception.dismissable,
                autoDismiss: exception.autoDismiss,
                autoDismissDelay: exception.autoDismissDelay,
                title: exception.statusText
            });
        } else {
            createToast(exception.message, {
                severity: exception.severity
            });
        }
        createNotification({
            severity: exception.severity,
            message: exception.message
        });
    }

    function handleLogging(
        message: string,
        logLevel?: "ERROR" | "DEBUG" | "WARN" | "INFO"
    ) {
        if (logLevel) {
            log(message, LogLevel[logLevel]);
        } else {
            log(message, LogLevel.INFO);
        }
    }

    function handleDismissChart() {
        onRequestDismiss(chartName);
    }

    function handleConnectLabMILine() {
        setConnectedLabMILine(!isLabMIPointsConnected);
    }

    const { chartState, onAction } = useChart({
        // TODO: Rename
        site,
        extruder,
        maxChartRange,
        minChartRange,
        onHandleException: handleException,
        onHandleLogging: handleLogging
    });

    const {
        chartRange,
        currentResinName,
        currentSupportedResins,
        isPaused,
        labData,
        predictionData,
        queuedAction,
        engineRequiresRestart,
        failedFetchAttempts
    } = chartState;

    const isChartAtMaxRange = chartRange === maxChartRange;

    function onActionButtonClick(action: ChartActions) {
        log(`Chart action: ${JSON.stringify(action)}`, LogLevel.INFO);
        trackEvent(
            createCustomEvent(`${JSON.stringify(ChartActions[action])}`, {
                customEventType: "Chart Action"
            })
        );
        onAction(action);
    }

    const handleOpenResinsModal = useCallback(() => {
        onRequestResinModalOpen(extruder, currentSupportedResins);
    }, [currentSupportedResins, extruder, onRequestResinModalOpen]);

    function handleChangeExtruder(extruder: string) {
        onRequestSelectExtruder(chartIndex, extruder);
    }

    const uclLine: Line = {
        className: "ucl",
        label: "USL",
        points: [],
        connectPoints: true
    };
    const lclLine: Line = {
        className: "lcl",
        label: "LSL",
        points: [],
        connectPoints: true
    };
    const predictedLine: Line = {
        className: "predicted",
        showPoints: true,
        points: [],
        markerRadius: isMultiChart ? 6 : 5,
        connectPoints: true
    };
    const targetLine: Line = {
        className: "target",
        label: "TMI",
        points: [],
        connectPoints: true
    };
    const labMILine: Line = {
        className: "labMI",
        showPoints: true,
        points: [],
        markerRadius: isMultiChart ? 6 : 5,
        connectPoints: isLabMIPointsConnected
    };

    let ucl: number | null = null;
    let lcl: number | null = null;
    let predicted: number | null = null;
    let target: number | null = null;
    let labMeltIndex: number | null = null;
    let pillStatus = "";
    let currentMeltIndexIcon = faHeartRate;
    let currentMeltIndexPillName = "CURRENT MI";
    const {
        numTicks: numXTicks,
        hideTicks: hideXTicks,
        hidePoints
    } = ChartRangeXTickTranslation[chartRange];

    let predictedLinePointsCn = "";
    let missingPredictionsCn = "no-prediction";
    if (isChartAtMaxRange || hidePoints) {
        predictedLinePointsCn += "invisible-points";
    }
    const validPredictionsToMap = predictionData.filter(
        ({ dataPoint }) => dataPoint
    );

    const missingPredictions = validPredictionsToMap.filter(
        ({ hasMissingPrediction }) => hasMissingPrediction
    );

    const allPredictionsAreMissing =
        missingPredictions.length === validPredictionsToMap.length;

    const validLabDataToMap = labData.filter(
        (dataPoint) => dataPoint.labMeltIndex
    );

    const hasValidDataToPlot =
        validPredictionsToMap.length !== 0 && !allPredictionsAreMissing;

    const canRenderPlot = hasValidDataToPlot;

    if (canRenderPlot) {
        uclLine.points = validPredictionsToMap
            .filter(({ dataPoint }) => dataPoint.ucl)
            .map(({ timeStamp, dataPoint }) => {
                return {
                    x: timeStamp,
                    y: dataPoint.ucl
                };
            });
        lclLine.points = validPredictionsToMap
            .filter(({ dataPoint }) => dataPoint.lcl)
            .map(({ timeStamp, dataPoint }) => {
                return {
                    x: timeStamp,
                    y: dataPoint.lcl
                };
            });

        targetLine.points = validPredictionsToMap
            .filter(({ dataPoint }) => dataPoint.target)
            .map(({ timeStamp, dataPoint }) => {
                return {
                    x: timeStamp,
                    y: dataPoint.target
                };
            });

        predictedLine.points = validPredictionsToMap.map(
            ({ timeStamp, dataPoint, hasMissingPrediction }, index) => {
                // Hide Every Other Missing Prediction When Chart Is At Max Range
                missingPredictionsCn = "no-prediction";
                if (
                    isChartAtMaxRange &&
                    missingPredictions.length > 2 &&
                    hasMissingPrediction &&
                    index % 2 !== 0
                ) {
                    missingPredictionsCn += " invisible-points";
                }
                return {
                    x: timeStamp,
                    y: dataPoint.predicted,
                    showTooltip: true,
                    tooltipOverride: hasMissingPrediction
                        ? "No Prediction"
                        : "",
                    className: hasMissingPrediction
                        ? missingPredictionsCn
                        : predictedLinePointsCn,
                    demonstrateAbsence: hasMissingPrediction
                };
            }
        );

        labMILine.points = validLabDataToMap.map((dataPoint) => {
            return {
                x: dataPoint.timeStamp,
                y: dataPoint.labMeltIndex,
                showTooltip: true
            };
        });

        const { dataPoint: lastPrediction, hasMissingPrediction } =
            validPredictionsToMap[validPredictionsToMap.length - 1];
        const lastLabMeltIndex =
            validLabDataToMap[validLabDataToMap.length - 1];

        if (lastPrediction) {
            lcl = lastPrediction.lcl;
            ucl = lastPrediction.ucl;
            predicted = lastPrediction.predicted;
            target = lastPrediction.target;
            if (predicted && lcl && ucl) {
                uclLine.showAlert = predicted >= ucl;
                lclLine.showAlert = predicted <= lcl;
                pillStatus = determinePillStatus(
                    predicted,
                    lcl,
                    ucl,
                    hasMissingPrediction
                );
            }
            if (hasMissingPrediction) {
                currentMeltIndexIcon = faCircleExclamation;
                currentMeltIndexPillName = "NO PREDICTION";
            }
        }

        if (lastLabMeltIndex) {
            labMeltIndex = lastLabMeltIndex.labMeltIndex;
        }
    }

    const lineChartMargin = isMultiChart
        ? { left: 70, right: 24, top: -20, bottom: 50 }
        : { left: 70, right: 5, top: 20, bottom: 50 };

    const xAxisLabelOverride =
        chartRange in [12, 24] ? hideTwoTicksAfter : hideEveryOtherTick;

    return (
        <div className={cn} data-testid={chartTestId}>
            <div className="header">
                <div
                    className="information"
                    data-testid={`${chartTestId}-${Chart_Elements.Train_Header}`}
                >
                    <ChartHeader
                        authorizedExtruders={authorizedExtruders}
                        chartName={chartName}
                        onRequestChangeExtruder={handleChangeExtruder}
                        chartTestId={chartTestId}
                    />
                    <ResinHeader
                        currentResinName={currentResinName}
                        currentSupportedResins={currentSupportedResins}
                        handleOpenResinsModal={handleOpenResinsModal}
                        chartTestId={chartTestId}
                    />
                </div>
                <ChartActionsHeader
                    paused={isPaused}
                    handleDismissChart={handleDismissChart}
                    chartTestId={chartTestId}
                    isMultiChart={isMultiChart}
                    onActionButtonClick={onActionButtonClick}
                    handleConnectLabMILine={handleConnectLabMILine}
                    isLabMIPointsConnected={isLabMIPointsConnected}
                    queuedAction={queuedAction}
                />
            </div>
            <div className="container">
                <ChartOverlay
                    isLoading={!!queuedAction}
                    hasError={engineRequiresRestart}
                    failedFetchAttempts={failedFetchAttempts}
                    chartTestId={chartTestId}
                />
                <div className="pills">
                    <Pill
                        name="UPPER SPEC LIMIT"
                        value={ucl}
                        icon={faArrowToTop}
                        chartTestId={chartTestId}
                    />
                    <Pill
                        name={currentMeltIndexPillName}
                        value={predicted}
                        status={pillStatus}
                        decimalPoints={4}
                        icon={currentMeltIndexIcon}
                        chartTestId={chartTestId}
                    />
                    <Pill
                        name="TARGET MI"
                        value={target}
                        icon={faBullseyeArrow}
                        chartTestId={chartTestId}
                    />
                    <Pill
                        name="LAST LAB MI"
                        value={labMeltIndex}
                        icon={faFlaskVial}
                        chartTestId={chartTestId}
                        decimalPoints={4}
                    />
                    <Pill
                        name="LOWER SPEC LIMIT"
                        value={lcl}
                        icon={faArrowToBottom}
                        chartTestId={chartTestId}
                    />
                </div>
                <div className="plot">
                    {canRenderPlot ? (
                        <LineChart
                            title="Melt Index"
                            aspectRatio={aspectRatio}
                            className="line-chart"
                            lines={[
                                uclLine,
                                lclLine,
                                targetLine,
                                predictedLine,
                                labMILine
                            ]}
                            axes={{
                                x: {
                                    mode: AxisMode.date,
                                    labelFormatter: xAxisLabelFormatter,
                                    indicators: [
                                        {
                                            determinantFunction: isDateMidnight,
                                            indicatorFactory:
                                                FormattedDateIndicator
                                        },
                                        {
                                            determinantFunction:
                                                isXAxisLeftmostDataPoint,
                                            indicatorFactory:
                                                FormattedDateIndicator
                                        }
                                    ],
                                    ticksConfig: {
                                        labelOverride: hideXTicks
                                            ? xAxisLabelOverride
                                            : null,
                                        numberOfTicks: numXTicks
                                    }
                                },
                                y: {
                                    mode: AxisMode.number,
                                    labelFormatter: yAxisLabelFormatter,
                                    ticksConfig: {
                                        numberOfTicks: 7
                                    }
                                }
                            }}
                            axisTickGenerator={tickFactory}
                            margin={lineChartMargin}
                            testId={`${chartTestId}-${Chart_Elements.Main_Plot}`}
                        />
                    ) : (
                        <h2
                            className="no-data-message"
                            data-testid={`${chartTestId}-${Chart_Elements.No_Data_Message}`}
                        >
                            Missing Chart Data
                        </h2>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Chart;

function determinePillStatus(
    value: number | null,
    lcl: number,
    ucl: number,
    hasMissingPrediction?: boolean
): string {
    let status = "";
    if (value) {
        const numValue = Number(value);
        if (numValue >= ucl || numValue <= lcl) {
            status = "alert";
        }
    }
    if (hasMissingPrediction) {
        status = "no-prediction";
    }
    return status;
}

function hideEveryOtherTick(value: AxisMode, index: number) {
    return index % 2 === 0 ? value : "";
}

function hideTwoTicksAfter(value: AxisMode, index: number) {
    return index % 3 === 0 ? value : "";
}

function xAxisLabelFormatter(value: AxisMode) {
    return twelveHourAndMinuteTimeFormat.format(new Date(value));
}

function yAxisLabelFormatter(value: AxisMode) {
    return precisionRound(value, 2).toString();
}
