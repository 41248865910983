import React from "react";
import { Section } from "../components/section";
import {
    HoverPointImg,
    CurrentMeltIndexImg,
    PillsHighlightedImg,
    ExtruderSelectionImg,
    PlotTimeDisplay,
    ResinHeaderImg,
    AlertBannersImg,
    ProductTransitionsImg,
    HistoryButtons,
    ControlLimitAlert,
    LabMIDataImg
} from "../assets/img";

export function Features() {
    return (
        <Section id="features">
            <h2>Digital Rheometer Features</h2>
            <p>
                The Digital Rheometer displays a melt index prediction chart for
                the resin type being produced for a given extruder. The Digital
                Rheometers available to the control room will automatically be
                displayed upon logging into the application.
            </p>
            <p>
                Melt Index forecasts are displayed on the chart in five-minute
                intervals to four decimal places. The specific four decimal
                forecast is shown in the Current Melt Index box on the right
                side of the screen. To see any forecasted Melt Index hover the
                mouse over the data point, and the point’s specific MI will
                display to four decimals.
            </p>
            <img src={HoverPointImg} alt="Hover Point" />
            <img src={CurrentMeltIndexImg} alt="Current Melt Index" />
            <p>
                Lab Melt Index data is plotted on the chart using purple data
                points. To see the lab data hover the mouse over the data point,
                and the points specific MI will display.
            </p>
            <img
                src={LabMIDataImg}
                alt="Lab Melt Index Data"
                className="rounded-image"
            />
            <p>
                Upper and lower spec limits (USL / LSL) are shown on the chart
                as grey dashed lines, while the Target MI is displayed as a
                solid green line; all are based on the resin type being produced
                and taken from SpecWebTool. Additionally, the USL, LSL, and
                Target MI are shown in boxes on the right side of the screen.
                See outlined in yellow below.
            </p>
            <img src={PillsHighlightedImg} alt="Highlighted Pills" />
            <h2>Multi-Chart and Train Selection</h2>
            <p>
                The screen can display multiple train charts simultaneously or
                individually by selecting the single or multi-train view option
                in the top right corner of the screen. To change from
                multi-train to single train view, click X in the top right
                corner of the chart display. To bring back the multi-train view,
                click the multi-train button in the top right corner of the
                screen and select the train you want to see added to the display
                (See highlighted in yellow sections in the image below).
            </p>
            <img src={ExtruderSelectionImg} alt="Extruder Selection" />
            <h2>Time Display</h2>
            <p>
                The screen plots the predicted MI points every five minutes
                continuously on the chart. The chart has timeframe options of
                six, four, and two hours. This is controlled by the +/- buttons
                in the top right corner. The chart can also go back in time to
                view past MI predictions. To do so, use the scroll bar options
                in the top right of the screen. After viewing past predictions,
                click the play button to return to real-time results. See the
                below image highlights.
            </p>
            <img src={PlotTimeDisplay} alt="Plot Time Display" />
            <img
                src={HistoryButtons}
                alt="History Buttons"
                className="rounded-image"
            />
            <h2>Off-spec Alarm</h2>
            <p>
                If the melt index reaches or surpasses the USL or LSL, the grey
                dashed line will turn red, and an alarm will appear on the
                Current Melt Index display box on the right side of the screen.
                See the below image.
            </p>
            <img src={ControlLimitAlert} alt="Spec Limit Alert" />
            <h2>Supported Resins</h2>
            <p>
                Select the information icon next to the resin name display to
                view a list of the resins trained for a specific model.
            </p>
            <p className="info">
                Note: The Melt Index display will not show any data point
                predictions for resin types that a model is not trained for.
            </p>
            <img src={ResinHeaderImg} alt="Resin Header" />
            <h2>Error Alerts</h2>
            <p>
                Errors can occur with the Digital Rheometer web application.
                When web application errors occur, banner alerts will be
                displayed at the top of the screen in a red bar describing the
                error that’s taken place. Errors can happen for several reasons
                and can self-correct when a new data point prediction is made.
                If an error does not self-correct after five predictions or 30
                minutes, please report the issue to the IT Service Help Desk.
                Share the error alert being displayed on the Digital Rheometer
                screen.
            </p>
            <p>Below are some of the potential errors that may appear:</p>
            <ul>
                <li>Error in fetching latest data point</li>
                <li>Error in getting initial data</li>
                <li>Error in getting earlier data undefined</li>
            </ul>
            <img src={AlertBannersImg} alt="Alert Banner" />
            <h2>Product Transitions</h2>
            <p>
                The Digital Rheometer automatically tracks along with
                transitions to the new resin type based on operator changes made
                in STARLIMS. No user interaction is needed within the Digital
                Rheometer to facilitate or track Melt Index along with a
                transition.
            </p>
            <img src={ProductTransitionsImg} alt="Product Transitions" />
        </Section>
    );
}
