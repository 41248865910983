import React from "react";
import { Section } from "../components/section";

export function ProductSupport() {
    return (
        <Section id="product-support">
            <h2>Product Support</h2>
            <p>
                The My IT Help Desk supports the Digital Rheometer. When
                interacting with the Help Desk share, you are working with the
                Digital Rheometer. Application errors can be reported to the
                help desk in three ways:{" "}
            </p>
            <ul>
                <li>
                    My IT Help Desk Portal:{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://myit.cpchem.net"
                    >
                        http://myit.cpchem.net
                    </a>
                </li>
                <ul>
                    <li>
                        Go to the My IT Help Desk Portal, go to the Report an
                        Application Issue in the Available Requests section, and
                        select Request Now. Input Digital Rheometer in the Name
                        of application with issue field and describe the issue
                        taking place with as much detail as possible.
                    </li>
                </ul>
                <li>Call (877) 227-2436 or (832) 813-4300</li>
            </ul>
        </Section>
    );
}
