import {
    log,
    LogEvent,
    LogManager,
    LogLevel,
    logsAtOrAbove,
    getTraceParent
} from "@cpchem/logging";
import {
    ApplicationInsights,
    IConfiguration
} from "@microsoft/applicationinsights-web";
import { onlyPageViews } from "./only-page-views";
import { onlyCustomEvents } from "./only-custom-events";

type AppInsightsConfig = IConfiguration | undefined;

export function configureAppInsights(
    appInsightsConfig: AppInsightsConfig
): ApplicationInsights | undefined {
    if (!appInsightsConfig || !appInsightsConfig.connectionString)
        return undefined;

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: appInsightsConfig?.connectionString,
            disableFetchTracking: false
        }
    });
    appInsights.loadAppInsights();
    return appInsights;
}

export function configureAppInsightsLogging(
    appInsights: ApplicationInsights | undefined
): void {
    if (!appInsights) {
        log("Cannot setup AppInsights Logging", LogLevel.ERROR);
        return;
    }

    LogManager.current().subscribeToEvents(
        pageViewEventSink(appInsights),
        onlyPageViews
    );
    LogManager.current().subscribeToEvents(
        customEventSink(appInsights),
        onlyCustomEvents
    );
    LogManager.current().subscribeToLogs(
        appErrorLogSink(appInsights),
        logsAtOrAbove(LogLevel.ERROR)
    );
}

export function identifyAppInsightsUser(
    appInsights: ApplicationInsights,
    validatedId: string
): void {
    appInsights.setAuthenticatedUserContext(validatedId);
}

export function clearAppInsightsIdenity(
    appInsights: ApplicationInsights
): void {
    appInsights.clearAuthenticatedUserContext();
}

type EventSink = (event: LogEvent) => void;

function pageViewEventSink(appInsights: ApplicationInsights): EventSink {
    return (event) => {
        const pageContents = JSON.parse(event.contents);

        const telemetry = {
            pageType: pageContents.page,
            properties: {
                ...pageContents.params
            }
        };
        appInsights.context.telemetryTrace.traceID = getTraceParent();
        appInsights.trackPageView(telemetry);
    };
}

function customEventSink(appInsights: ApplicationInsights): EventSink {
    return (event) => {
        const eventContents = JSON.parse(event.contents);
        const customProperties = eventContents.properties;

        const telemetry = {
            name: eventContents.name
        };
        appInsights.trackEvent(telemetry, customProperties);
    };
}

type LogSink = (level: string, message: string) => void;

function appErrorLogSink(appInsights: ApplicationInsights): LogSink {
    return (_, message) => {
        const telemetry = {
            message
        };
        appInsights.trackTrace(telemetry);
    };
}
