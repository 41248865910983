import { LabMIDataPoint, Message, PredictionDataPoint } from "@services";
import { getCurrentDate } from "@utilities/date";
import getLatestReasonableChartWindowEnd from "./get-latest-reasonable-chart-window-end";
import getNewWindowEndDate from "./get-new-window-end-date";

// Chart Actions entries are in minutes.
export enum ChartActions {
    Play = 1,
    Pause = 0,
    FastForward = 60,
    FastBackward = -60,
    ExtendChartTimeRange = "ExtendChartTimeRange",
    ShrinkChartTimeRange = "ShrinkChartTimeRange"
}

export interface ChartRangeMap {
    [index: number]: number;
}

export const chartRanges: ChartRangeMap = {
    1: 2,
    2: 4,
    3: 6,
    4: 12,
    5: 24
};

// Chart State
export interface ChartState {
    predictionData: PredictionDataPoint[];
    labData: LabMIDataPoint[];
    isPaused: boolean;
    chartRange: number;
    queuedAction: ChartActions | null;
    currentSupportedResins: string[];
    currentResinName: string;
    currentScoringMessages: Message[];
    engineRequiresRestart: boolean;
    failedFetchAttempts: number;
}

export const initialChartState: ChartState = {
    predictionData: [],
    labData: [],
    isPaused: false,
    chartRange: 12,
    queuedAction: null,
    currentSupportedResins: [],
    currentResinName: "",
    currentScoringMessages: [],
    engineRequiresRestart: false,
    failedFetchAttempts: 0
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const nextForcedEndDate = {
    [ChartActions.Play]: (currentWindowEnd: Date): Date =>
        getLatestReasonableChartWindowEnd(getCurrentDate),

    [ChartActions.Pause]: (currentWindowEnd: Date): Date => currentWindowEnd,

    [ChartActions.FastBackward]: (currentWindowEnd: Date): Date =>
        getNewWindowEndDate(currentWindowEnd, ChartActions.FastBackward),

    [ChartActions.FastForward]: (currentWindowEnd: Date): Date =>
        getNewWindowEndDate(currentWindowEnd, ChartActions.FastForward),

    [ChartActions.ExtendChartTimeRange]: (currentWindowEnd: Date) => {
        return currentWindowEnd;
    },
    [ChartActions.ShrinkChartTimeRange]: (currentWindowEnd: Date) => {
        return currentWindowEnd;
    }
};

export const nextPauseState = {
    [ChartActions.Play]: (currentPausedState: boolean) => false,
    [ChartActions.Pause]: (currentPausedState: boolean) => true,
    [ChartActions.FastBackward]: (currentPausedState: boolean) => true,
    [ChartActions.FastForward]: (currentPausedState: boolean) => true,
    [ChartActions.ExtendChartTimeRange]: (currentPausedState: boolean) =>
        currentPausedState,
    [ChartActions.ShrinkChartTimeRange]: (currentPausedState: boolean) =>
        currentPausedState
};

export const nextChartRangeState = {
    [ChartActions.Play]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ) => currentChartRange,
    [ChartActions.Pause]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ) => currentChartRange,
    [ChartActions.FastBackward]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ) => currentChartRange,
    [ChartActions.FastForward]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ) => currentChartRange,
    [ChartActions.ExtendChartTimeRange]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ): number => {
        if (currentChartRange === maxChartRange) return maxChartRange;
        const currentChartRangeIndex =
            Object.keys(chartRanges)[
                Object.values(chartRanges).indexOf(currentChartRange)
            ];
        const newChartRangeIndex = Number(currentChartRangeIndex) + 1;
        const newChartRange = chartRanges[newChartRangeIndex];
        if (newChartRange > maxChartRange) return maxChartRange;
        return newChartRange;
    },
    [ChartActions.ShrinkChartTimeRange]: (
        currentChartRange: number,
        maxChartRange: number,
        minChartRange: number,
        action: ChartActions
    ) => {
        if (currentChartRange === minChartRange) return minChartRange;
        const currentChartRangeIndex =
            Object.keys(chartRanges)[
                Object.values(chartRanges).indexOf(currentChartRange)
            ];
        const newChartRangeIndex = Number(currentChartRangeIndex) - 1;
        const newChartRange = chartRanges[newChartRangeIndex];
        if (newChartRange < minChartRange) return minChartRange;
        return newChartRange;
    }
};

interface ChartRangeTickTranslation {
    [chartRange: number]: {
        numTicks: number;
        hideTicks: boolean;
        hidePoints?: boolean;
    };
}

export const ChartRangeXTickTranslation: ChartRangeTickTranslation = {
    24: {
        numTicks: 19,
        hideTicks: true,
        hidePoints: true
    },
    12: {
        numTicks: 15,
        hideTicks: true,
        hidePoints: true
    },
    6: {
        numTicks: 13,
        hideTicks: true
    },
    4: {
        numTicks: 9,
        hideTicks: false
    },
    2: {
        numTicks: 5,
        hideTicks: false
    }
};

export interface ChartEngineException {
    message: string;
    severity: "info" | "success" | "danger" | "caution";
    dismissable?: boolean;
    autoDismiss?: boolean;
    autoDismissDelay?: number;
    statusText?: string;
}

interface EDPSeverity {
    [severity: string]: "info" | "success" | "danger" | "caution";
}

export const EDPSeverityTranslation: EDPSeverity = {
    ["critical" || "error"]: "danger",
    ["warning"]: "caution",
    ["info"]: "info"
};
