import { PredictionDataPoint, DataPoint } from "@services";
import { composeChartDataPoint } from "./compose-chart-datapoint";

export function fillMissingPredictions(
    data: DataPoint[]
): PredictionDataPoint[] {
    return data.map((dataPoint, index) => {
        const isFirstDataPoint = index === 0;
        const lastPredictedValue =
            data[isFirstDataPoint ? index + 1 : index - 1].predicted;
        return composeChartDataPoint(
            dataPoint,
            lastPredictedValue
        ) as PredictionDataPoint;
    });
}
