import React from "react";
import { Button } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./style.scss";

export interface IconButtonProps {
    className?: string;
    icon: IconDefinition;
    onClick: () => void;
    isActive?: boolean;
    ariaLabel: string;
    testId?: string;
    isDisabled?: boolean;
}

export function IconButton({
    className,
    icon,
    onClick,
    isActive,
    ariaLabel,
    testId,
    isDisabled
}: IconButtonProps): JSX.Element {
    let cn = "icon-button";
    const active_cn = "active";
    const disabled_cn = "disabled";

    if (className) {
        cn += ` ${className}`;
    }

    if (isActive) {
        cn += ` ${active_cn}`;
    }

    if (isDisabled) {
        cn += ` ${disabled_cn}`;
    }

    return (
        <Button
            testId={testId}
            isDisabled={isDisabled}
            className={cn}
            onClick={onClick}
            aria-label={ariaLabel}
            variant="text"
            icon={<FontAwesomeIcon className="icon" icon={icon as IconProp} />}
        />
    );
}
