/* eslint-disable class-methods-use-this */
import {
    RheometerService,
    RheometerPredictionCallback,
    RheometerLabMICallback,
    FetchOptions,
    ServiceLocator
} from "@services";
import bind from "bind-decorator";
import {
    DataPoint,
    ModelData,
    ScoringMessagesData,
    Site,
    Extruder,
    LabMIDataPoint,
    apiDataPoint,
    apiLabMIDataPoint
} from "./model";
import config from "@config";
import { ApiResponse } from "@services/api-response";
import { parseISO } from "date-fns";
import { log, LogLevel } from "@cpchem/logging";
import {
    FetchinterceptorService,
    FetchServiceKey
} from "@services/fetchInterceptor";
import { nullablePrecisionRound, precisionRound } from "@utilities/math";

interface Observer {
    site: string;
    extruder: string;
    predictionCallback: RheometerPredictionCallback;
    labMICallback: RheometerLabMICallback;
}

interface SiteExtruder {
    site: string;
    extruder: string;
}

export class RheometerServiceImplementation implements RheometerService {
    private readonly base = config.api.rheometer.url;
    private readonly scopes = config.api.rheometer.scopes;
    private FetchOptionsService: FetchinterceptorService;
    private _observers: Observer[] = [];

    constructor() {
        this.FetchOptionsService =
            ServiceLocator.get<FetchinterceptorService>(FetchServiceKey);
        window.setInterval(
            this.getCurrentLabMeltIndex,
            config.pollingFrequencyInMS
        );
        window.setInterval(
            this.getCurrentPredictions,
            config.pollingFrequencyInMS
        );
    }

    @bind
    private async ensureFetchOptionsAsync(
        method = "GET"
    ): Promise<FetchOptions> {
        return this.FetchOptionsService.getFetchOptionsAsync(
            this.base,
            this.scopes,
            method
        );
    }

    @bind
    SubscribeToUpdates(
        site: string,
        extruder: string,
        predictionCallback: RheometerPredictionCallback,
        labMICallback: RheometerLabMICallback
    ): void {
        const observer = { site, extruder, predictionCallback, labMICallback };
        if (this.getObserverIndex(observer) !== -1) {
            log("Duplicate observer detected", LogLevel.ERROR);
            throw "Duplicate observer detected";
        }
        log(
            `Observer subscribed: ${observer.site} and extruder ${observer.extruder}`,
            LogLevel.INFO
        );
        this._observers.push(observer);
    }

    @bind
    UnsubscribeFromUpdates(
        site: string,
        extruder: string,
        predictionCallback: RheometerPredictionCallback,
        labMICallback: RheometerLabMICallback
    ): void {
        const observer = { site, extruder, predictionCallback, labMICallback };
        const observerIndex = this.getObserverIndex(observer);
        if (observerIndex === -1) {
            log("Nonexistent observer", LogLevel.WARN);
            return;
        }
        log(
            `Observer Unsubscribed: ${observer.site} and extruder ${observer.extruder}`,
            LogLevel.INFO
        );
        this._observers.splice(observerIndex, 1);
    }

    @bind
    private getObserverIndex({
        site,
        extruder,
        predictionCallback,
        labMICallback
    }: Observer): number {
        let observerIndex = -1;
        if (this._observers.length !== 0) {
            this._observers.map((observer, index) => {
                if (
                    observer.site === site &&
                    observer.extruder === extruder &&
                    observer.predictionCallback === predictionCallback &&
                    observer.labMICallback === labMICallback
                ) {
                    observerIndex = index;
                }
            });
        }
        return observerIndex;
    }

    @bind
    private getUniqueSiteExtruders(): SiteExtruder[] {
        if (this._observers.length !== 0) {
            const uniqueSiteExtruders: SiteExtruder[] = [];
            this._observers.filter((observer) => {
                const index = uniqueSiteExtruders.findIndex(
                    (x) =>
                        x.site === observer.site &&
                        x.extruder === observer.extruder
                );
                if (index === -1) {
                    uniqueSiteExtruders.push({
                        site: observer.site,
                        extruder: observer.extruder
                    });
                }
                return;
            });
            return uniqueSiteExtruders;
        }
        return [];
    }

    @bind
    async GetHistoryAsync(
        site: string,
        extruder: string,
        start: Date,
        end: Date
    ): Promise<ApiResponse<DataPoint[]>> {
        try {
            const uri = `${
                this.base
            }/history/${site}/${extruder}?start=${start.toISOString()}&end=${end.toISOString()}`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const apiDataPoints = (await response.json()) as apiDataPoint[];
                const dataPoints: DataPoint[] = [];
                apiDataPoints.map((dp) => {
                    dataPoints.push({
                        lcl: nullablePrecisionRound(dp.lcl, 2),
                        predicted: nullablePrecisionRound(dp.predicted, 4),
                        target: nullablePrecisionRound(dp.target, 2),
                        timeStamp: parseISO(dp.timeStamp),
                        ucl: nullablePrecisionRound(dp.ucl, 2),
                        resinName: dp.resinName,
                        model: dp.model,
                        runId: dp.runId,
                        hasMessages: dp.hasMessages
                    });
                });
                const sortedDataPoints = dataPoints.sort(
                    (a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()
                );
                return {
                    data: sortedDataPoints
                };
            }

            if (response.status === 404) {
                log(
                    `Data not found for ${site} and extruder ${extruder}`,
                    LogLevel.ERROR
                );
                return {
                    error: `Data not found for ${site} and extruder ${extruder}`
                };
            }

            log(
                `api/history: Unknown error for ${site} and extruder ${extruder}. Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch historical data."
            };
        } catch (error) {
            log(
                `api/history: Unknown error for ${site} and extruder ${extruder}. Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch historical data."
            };
        }
    }

    @bind
    async GetModelAsync(model: string): Promise<ApiResponse<ModelData>> {
        try {
            const uri = `${this.base}/models/${model}`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const data = (await response.json()) as ModelData;

                return {
                    data
                };
            }

            if (response.status === 404) {
                log(`Data not found for model: ${model}`, LogLevel.ERROR);
                return {
                    error: `Data not found for model: ${model}`
                };
            }

            log(
                `api/models: Unknown error for model: ${model}. Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch historical data."
            };
        } catch (error) {
            log(
                `api/models: Unknown error for model: ${model}. Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch model data."
            };
        }
    }

    @bind
    async GetScoringMessages(
        runId: string
    ): Promise<ApiResponse<ScoringMessagesData>> {
        try {
            const uri = `${this.base}/messages/${runId}`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const data = (await response.json()) as ScoringMessagesData;

                return {
                    data
                };
            }

            if (response.status === 404) {
                log(`Messages not found for Run Id: ${runId}`, LogLevel.ERROR);
                return {
                    error: `Messages not found for Run Id: ${runId}`
                };
            }

            log(
                `api/messages: Unknown error for Run Id: ${runId}. Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch messages."
            };
        } catch (error) {
            log(
                `api/messages: Unknown error for Run Id: ${runId}. Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch messages."
            };
        }
    }

    @bind
    async GetSitesAsync(): Promise<ApiResponse<Site[]>> {
        try {
            const uri = `${this.base}/sites`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const data = (await response.json()) as Site[];

                return {
                    data
                };
            }

            if (response.status === 404) {
                log("Sites data not found", LogLevel.ERROR);
                return {
                    error: `Sites data not found`
                };
            }

            log(
                `api/sites: Unknown error fetching sites: Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch sites."
            };
        } catch (error) {
            log(
                `api/sites: Unknown error fetching sites: Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch sites."
            };
        }
    }

    @bind
    async GetExtrudersAsync(site?: string): Promise<ApiResponse<Extruder[]>> {
        try {
            const uri = site
                ? `${this.base}/extruders?site=${site}`
                : `${this.base}/extruders`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const data = (await response.json()) as Extruder[];

                return {
                    data
                };
            }

            if (response.status === 404) {
                log("Extruders data not found", LogLevel.ERROR);
                return {
                    error: `Extruders data not found`
                };
            }

            log(
                `api/extruders: Unknown error fetching Extruders: Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch extruders."
            };
        } catch (error) {
            log(
                `api/extruders: Unknown error fetching extruders: Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch extruders."
            };
        }
    }

    @bind
    async GetLabMeltIndexAsync(
        site: string,
        extruder: string,
        start: Date,
        end: Date
    ): Promise<ApiResponse<LabMIDataPoint[]>> {
        try {
            const uri = `${
                this.base
            }/labmi/history/${site}/${extruder}?start=${start.toISOString()}&end=${end.toISOString()}`;

            const options = await this.ensureFetchOptionsAsync();

            const response = await fetch(uri, options);

            if (response.ok) {
                const apiDataPoints =
                    (await response.json()) as apiLabMIDataPoint[];
                const dataPoints: LabMIDataPoint[] = [];
                apiDataPoints.map((dp) => {
                    dataPoints.push({
                        runId: dp.runId,
                        resinName: dp.resinName,
                        labMeltIndex: precisionRound(dp.labMeltIndex, 4),
                        timeStampRaw: parseISO(dp.timeStampRaw),
                        timeStamp: parseISO(dp.timeStamp)
                    });
                });
                const sortedDataPoints = dataPoints.sort(
                    (a, b) => a.timeStamp.getTime() - b.timeStamp.getTime()
                );
                return {
                    data: sortedDataPoints
                };
            }

            if (response.status === 404) {
                log(
                    `Lab Melt Index Data not found for ${site} and ${extruder}`,
                    LogLevel.ERROR
                );
                return {
                    error: `Lab Melt Index Data not found for ${site} and ${extruder}`
                };
            }

            log(
                `api/history: Unknown error for ${site} and ${extruder}. Status ${response.statusText}`,
                LogLevel.ERROR
            );

            return {
                error: "Failed in attempt to fetch lab melt index data."
            };
        } catch (error) {
            log(
                `api/history: Unknown error for ${site} and ${extruder}. Error ${error}`,
                LogLevel.ERROR
            );
            return {
                error: "Unknown error in attempt to fetch lab melt index data."
            };
        }
    }

    @bind
    private async getCurrentPredictions() {
        const uniqueSiteExtruders = this.getUniqueSiteExtruders();
        if (uniqueSiteExtruders.length !== 0) {
            uniqueSiteExtruders.forEach(async ({ site, extruder }) => {
                try {
                    const uri = `${this.base}/current/${site}/${extruder}`;

                    const options = await this.ensureFetchOptionsAsync();

                    const response = await fetch(uri, options);

                    const observersToSendUpdates = this._observers.filter(
                        (observer) =>
                            observer.site === site &&
                            observer.extruder === extruder
                    );

                    if (response.ok) {
                        const dataPoint = await response.json();
                        dataPoint[0].timeStamp = parseISO(
                            dataPoint[0].timeStamp
                        );

                        dataPoint[0].predicted = nullablePrecisionRound(
                            dataPoint[0].predicted,
                            4
                        );

                        dataPoint[0].lcl = nullablePrecisionRound(
                            dataPoint[0].lcl,
                            2
                        );

                        dataPoint[0].ucl = nullablePrecisionRound(
                            dataPoint[0].ucl,
                            2
                        );

                        dataPoint[0].target = nullablePrecisionRound(
                            dataPoint[0].target,
                            2
                        );

                        observersToSendUpdates.forEach(
                            ({ predictionCallback }) =>
                                predictionCallback({ data: dataPoint[0] })
                        );
                        return;
                    }

                    log(
                        `api/current: Unknown error for ${site} and extruder ${extruder}. Status ${response.statusText}`,
                        LogLevel.ERROR
                    );
                    observersToSendUpdates.forEach(({ predictionCallback }) =>
                        predictionCallback({
                            error: "Failed in attempt to fetch latest data point."
                        })
                    );
                } catch (error) {
                    log(
                        `api/current: Unknown error for ${site} and extruder ${extruder}. Error ${error}`,
                        LogLevel.ERROR
                    );
                    const observersToSendErrors = this._observers.filter(
                        (observer) =>
                            observer.site === site &&
                            observer.extruder === extruder
                    );
                    observersToSendErrors.forEach(({ predictionCallback }) =>
                        predictionCallback({
                            error: "Unknown error in attempt to fetch latest data point."
                        })
                    );
                }
            });
        }
    }

    @bind
    private async getCurrentLabMeltIndex() {
        const uniqueSiteExtruders = this.getUniqueSiteExtruders();
        if (uniqueSiteExtruders.length !== 0) {
            uniqueSiteExtruders.forEach(async ({ site, extruder }) => {
                try {
                    const uri = `${this.base}/labmi/current/${site}/${extruder}`;

                    const options = await this.ensureFetchOptionsAsync();

                    const response = await fetch(uri, options);

                    const observersToSendUpdates = this._observers.filter(
                        (observer) =>
                            observer.site === site &&
                            observer.extruder === extruder
                    );

                    if (response.ok) {
                        const data = await response.json();

                        const labMIDataPoint = data[0];

                        labMIDataPoint.timeStamp = parseISO(
                            labMIDataPoint.timeStamp
                        );

                        labMIDataPoint.labMeltIndex = nullablePrecisionRound(
                            labMIDataPoint.labMeltIndex,
                            4
                        );

                        observersToSendUpdates.forEach(({ labMICallback }) =>
                            labMICallback({
                                data: labMIDataPoint as LabMIDataPoint
                            })
                        );
                        return;
                    }
                    log(
                        `api/labmi/current: Unknown error for ${site} and ${extruder}. Status ${response.statusText}`,
                        LogLevel.ERROR
                    );
                    observersToSendUpdates.forEach(({ labMICallback }) =>
                        labMICallback({
                            error: "Failed in attempt to fetch latest lab melt index."
                        })
                    );
                } catch (error) {
                    log(
                        `api/labmi/current: Unknown error for ${site} and ${extruder}. Error ${error}`,
                        LogLevel.ERROR
                    );
                    const observersToSendErrors = this._observers.filter(
                        (observer) =>
                            observer.site === site &&
                            observer.extruder === extruder
                    );
                    observersToSendErrors.forEach(({ labMICallback }) =>
                        labMICallback({
                            error: "Unknown error in attempt to fetch latest lab melt index."
                        })
                    );
                }
            });
        }
    }
}
