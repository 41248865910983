import { LogEvent, LogManager, logsAtOrAbove, LogLevel } from "@cpchem/logging";
import { isInDevEnvironment } from "./in-dev-environment";
import config from "@config";

export function configureLogging(env: string): void {
    const inDevEnv = isInDevEnvironment(env);
    const environmentSpecificLogFilter = logsAtOrAbove(
        inDevEnv ? LogLevel.INFO : LogLevel.ERROR
    );

    LogManager.initialize({
        appVersion: import.meta.env.PACKAGE_VERSION,
        appInsights: config.logging.appInsights
    });

    LogManager.current().subscribeToLogs(
        consoleLogger,
        environmentSpecificLogFilter
    );

    LogManager.current().subscribeToEvents(consoleEventLogger);
}

function consoleLogger(level: string, message: string): void {
    console.log(`${level}: ${message}`);
}

function consoleEventLogger(event: LogEvent): void {
    console.log(`Logged event: ${event.type}. Contents: ${event.contents}`);
}
