import React, { useEffect, useState, useRef } from "react";
import { AspectRatio } from "./models/aspect-ratio";
import { Line } from "./models/line";
import { XType } from "./models/x-type";
import { AxisMode } from "./models/axis-mode";
import { precisionRound } from "@utilities/math";
import "./line-chart.scss";
import {
    Axes,
    Axis,
    Margin,
    Tick,
    TicksConfig
} from "./models/line-chart-types";
import { getScaledX } from "./utilities/scaled-x";
import { getScaledY } from "./utilities/scaled-y";
import { ScaledPoint } from "./models/scaled-points";
import { isDate, twelveHourAndMinuteTimeFormat } from "@utilities/date";

interface LineRectCoords {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface LineChartProps {
    className?: string;
    aspectRatio: AspectRatio;
    title?: string;
    description?: string;
    lines: Line[];
    rangePadding?: number;
    axes: Axes;
    axisTickGenerator: (
        origin: XType,
        end: XType,
        ticksConfig: TicksConfig | null,
        width: number,
        innerWidth: number,
        height: number,
        innerHeight: number,
        margin: Margin,
        axis: "y" | "x",
        axisProperties: Axis
    ) => Tick[];
    margin?: Margin;
    testId?: string;
}

export function LineChart({
    aspectRatio,
    className,
    title,
    description,
    lines,
    rangePadding = 10,
    axes,
    axisTickGenerator,
    margin = { left: 50, right: 24, top: 15, bottom: 50 },
    testId
}: LineChartProps): JSX.Element {
    const mainRef = useRef<SVGSVGElement>(null);
    const [linesRectCoords, setLinesRectCoords] = useState<LineRectCoords[]>(
        []
    );

    const { x: xAxis, y: yAxis } = axes;

    useEffect(() => {
        const lrc: LineRectCoords[] = [];
        const lineElements = mainRef.current?.getElementsByClassName("line");
        if (lineElements?.length) {
            for (
                let lineIndex = 0;
                lineIndex < lineElements.length;
                lineIndex++
            ) {
                const lineElement = lineElements[lineIndex];
                const textElements = lineElement.getElementsByTagName("text");
                if (textElements.length) {
                    const bbox = textElements[0].getBBox();
                    lrc.push({
                        x: bbox.x - 10,
                        y: bbox.y,
                        width: bbox.width + 20,
                        height: bbox.height
                    });
                }
            }
        }
        setLinesRectCoords(lrc);
    }, [lines]);

    let cn = "line-chart";
    if (className) {
        cn += ` ${className}`;
    }

    const { xMin, xMax, yMin, yMax } = getLinesExtent(lines);
    const { origin: xOrigin, end: xEnd } = getAxisRange(
        xMin,
        xMax,
        0,
        xAxis.mode
    );
    const { origin: yOrigin, end: yEnd } = getAxisRange(
        yMin,
        yMax,
        rangePadding,
        yAxis.mode
    );

    const { width, height } = getViewBox[aspectRatio];

    const viewBox = `0 0 ${width} ${height}`;

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const yAxisMargin = title ? margin.left - 25 : margin.left / 2;
    const titleY = height / 2;
    const titleX = 20;
    const tooltipWidth = 60;
    const tooltipHeight = 47;

    const xTicks = axisTickGenerator(
        xOrigin,
        xEnd,
        xAxis.ticksConfig || null,
        width,
        innerWidth,
        height,
        innerHeight,
        margin,
        "x",
        xAxis
    );
    const yTicks = axisTickGenerator(
        yOrigin,
        yEnd,
        yAxis.ticksConfig || null,
        width,
        innerWidth,
        height,
        innerHeight,
        margin,
        "y",
        yAxis
    );

    function _getPolyLinePoints(line: Line) {
        return getPolyLinePoints(
            xOrigin,
            xEnd,
            yOrigin as number,
            yEnd as number,
            innerWidth,
            innerHeight,
            margin,
            line
        );
    }

    function _getPolyLines(scaledPoints: ScaledPoint[]) {
        return getPolyLines(scaledPoints);
    }

    return (
        <svg
            className={cn}
            viewBox={viewBox}
            ref={mainRef}
            data-testid={testId}
        >
            {title && (
                <g transform={`translate(${titleX}, ${titleY})`}>
                    <text className="title" transform="rotate(-90)">
                        {title}
                    </text>
                </g>
            )}
            {description && <desc>{description}</desc>}

            <rect
                x={margin.left}
                y={margin.top}
                width={innerWidth}
                height={innerHeight}
                className="plot-background"
            />

            <g className="axis x">
                <line
                    x1={margin.left}
                    x2={width - margin.right}
                    y1={height - margin.bottom}
                    y2={height - margin.bottom}
                />
                <g className="labels">
                    {xTicks.map(({ x1, label, indicator }) => (
                        <g key={`x-label-${x1}`} className="x-label">
                            {indicator && indicator}
                            <text
                                x={x1}
                                y={height - margin.bottom / 2}
                                dominantBaseline="top"
                                key={`x-label-${x1}`}
                            >
                                {label}
                            </text>
                        </g>
                    ))}
                </g>
            </g>

            <g className="axis y">
                <line
                    x1={margin.left}
                    x2={margin.left}
                    y1={margin.top}
                    y2={height - margin.bottom}
                />
                <g className="labels">
                    {yTicks.map(({ y1, label }) => (
                        <text
                            x={yAxisMargin}
                            y={y1}
                            dominantBaseline="middle"
                            key={`x-label-${y1}`}
                        >
                            {label}
                        </text>
                    ))}
                </g>
            </g>

            <g className="ticks x">
                {xTicks.map(({ x1, x2, y1, y2 }) => (
                    <line
                        x1={x1}
                        x2={x2}
                        y1={y1}
                        y2={y2}
                        key={`${x1}-${x2}-${y1}-${y2}`}
                    />
                ))}
            </g>
            <g className="ticks y">
                {yTicks.map(({ x1, x2, y1, y2 }) => (
                    <line
                        x1={x1}
                        x2={x2}
                        y1={y1}
                        y2={y2}
                        key={`${x1}-${x2}-${y1}-${y2}`}
                    />
                ))}
            </g>

            {lines.map((line, lineIndex) => {
                if (!line.points.length) {
                    return null;
                }
                const scaledPoints = _getPolyLinePoints(line);
                const polylines = _getPolyLines(scaledPoints);
                const polylinePoints = polylines.map((line, index) => {
                    const polyLine = line.map(({ x, y }) => {
                        return `${x},${y}`;
                    });
                    return { id: index, points: polyLine.join() };
                });

                let lineClassName = "line";
                if (line.className) {
                    lineClassName += ` ${line.className}`;
                }
                if (line.showAlert) {
                    lineClassName += ` alert`;
                }
                return (
                    <g className={lineClassName} key={line.className}>
                        {line.connectPoints &&
                            polylinePoints.map(({ id, points }) => (
                                <polyline key={id} points={points} />
                            ))}

                        {line.showPoints &&
                            scaledPoints.map(
                                (
                                    {
                                        x,
                                        y,
                                        originalX,
                                        originalY: tooltipOriginalY,
                                        showTooltip,
                                        tooltipOverride,
                                        className
                                    },
                                    scaledPointIndex
                                ) => {
                                    if (!x || !y) {
                                        return null;
                                    }
                                    const tooltipOriginalX = isDate(originalX)
                                        ? twelveHourAndMinuteTimeFormat.format(
                                              originalX
                                          )
                                        : originalX;
                                    return (
                                        <g
                                            className="point"
                                            key={`${lineClassName}-${line.points[scaledPointIndex].x}`}
                                        >
                                            <circle
                                                className={className}
                                                cx={x}
                                                cy={y || 0}
                                                r={line.markerRadius}
                                            />

                                            {showTooltip && tooltipOverride ? (
                                                <title>{tooltipOverride}</title>
                                            ) : (
                                                <svg
                                                    x={x - 30}
                                                    y={y - 55}
                                                    className="tooltip"
                                                    height={tooltipHeight}
                                                    width={tooltipWidth}
                                                >
                                                    <rect
                                                        height={
                                                            tooltipHeight - 5
                                                        }
                                                        width={tooltipWidth}
                                                        rx={2}
                                                    />
                                                    <path
                                                        d="M5.25 5.75L0.0538487 0.874999L10.4462 0.875L5.25 5.75Z"
                                                        className="caret"
                                                        transform={`translate(${25}, ${40})`}
                                                    />
                                                    <text
                                                        className="y-label"
                                                        x="50%"
                                                        y="30%"
                                                        dominantBaseline="middle"
                                                        textAnchor="middle"
                                                    >
                                                        {tooltipOriginalY}
                                                    </text>
                                                    <text
                                                        className="x-label"
                                                        x="50%"
                                                        y="70%"
                                                        dominantBaseline="middle"
                                                        textAnchor="middle"
                                                    >
                                                        {tooltipOriginalX}
                                                    </text>
                                                </svg>
                                            )}
                                        </g>
                                    );
                                }
                            )}
                        {line.label && (
                            <g className="label">
                                {linesRectCoords[lineIndex] && (
                                    <rect
                                        x={linesRectCoords[lineIndex].x}
                                        y={linesRectCoords[lineIndex].y}
                                        width={linesRectCoords[lineIndex].width}
                                        height={
                                            linesRectCoords[lineIndex].height
                                        }
                                    />
                                )}
                                <text
                                    dominantBaseline="middle"
                                    x={scaledPoints[0].x + margin.left / 2}
                                    y={scaledPoints[0].y || 0}
                                >
                                    {line.label}
                                </text>
                            </g>
                        )}
                    </g>
                );
            })}
        </svg>
    );
}

export const getViewBox = {
    [AspectRatio["1:1"]]: {
        width: 768,
        height: 768
    },
    [AspectRatio["2:1"]]: {
        width: 1024,
        height: 512
    },
    [AspectRatio["3:1"]]: {
        width: 1024,
        height: 360
    },
    [AspectRatio["3:2"]]: {
        width: 1024,
        height: 682
    },
    [AspectRatio["4:1"]]: {
        width: 1024,
        height: 256
    },
    [AspectRatio["4:3"]]: {
        width: 1024,
        height: 768
    },
    [AspectRatio["16:7"]]: {
        width: 1024,
        height: 448
    },
    [AspectRatio["16:9"]]: {
        width: 1024,
        height: 576
    },
    [AspectRatio["16:10"]]: {
        width: 1024,
        height: 640
    }
};

function getLinesExtent(lines: Line[]): {
    xMin: XType;
    xMax: XType;
    yMin: number;
    yMax: number;
} {
    let xMin: XType | null = null;
    let xMax: XType | null = null;
    let yMin: number | null = null;
    let yMax: number | null = null;

    lines.map(({ points }) => {
        points.map(({ x, y }) => {
            if (xMin === null || xMax === null) {
                xMin = x;
                xMax = x;
            } else if (x < xMin) {
                xMin = x;
            } else if (x > xMax) {
                xMax = x;
            }

            if (!y) {
                return;
            }

            if (yMin === null || yMax === null) {
                yMin = y;
                yMax = y;
            } else if (y < yMin) {
                yMin = y;
            } else if (y > yMax) {
                yMax = y;
            }
        });
    });

    if (xMin === null || xMax === null || yMin === null || yMax === null) {
        throw "Unable to get lines extent. Likely lines was an empty array";
    }

    return {
        xMin,
        xMax,
        yMin,
        yMax
    };
}

function getAxisRange(
    min: XType,
    max: XType,
    rangePadding: number,
    mode: AxisMode
): { origin: XType; end: XType } {
    if (mode === AxisMode.number) {
        return getNumberAxisRange(min as number, max as number, rangePadding);
    }
    return getDateAxisRange(min as Date, max as Date, rangePadding);
}

function getNumberAxisRange(
    min: number,
    max: number,
    rangePadding: number
): { origin: number; end: number } {
    const range = max - min;
    const expandedRange = range * (0.01 * rangePadding + 1);
    const diff = expandedRange - range;
    return {
        origin: precisionRound(min === 0 ? 0 : min - diff, 2),
        end: precisionRound(max + diff, 2)
    };
}
function getDateAxisRange(
    min: Date,
    max: Date,
    rangePadding: number
): { origin: Date; end: Date } {
    const { origin, end } = getNumberAxisRange(
        min.getTime(),
        max.getTime(),
        rangePadding
    );
    return {
        origin: new Date(origin),
        end: new Date(end)
    };
}

function getPolyLinePoints(
    xOrigin: XType,
    xEnd: XType,
    yOrigin: number,
    yEnd: number,
    innerWidth: number,
    innerHeight: number,
    margin: { left: number; top: number },
    { points }: Line
): ScaledPoint[] {
    const scaledPoints: ScaledPoint[] = [];

    points.map(
        ({
            x,
            y,
            showTooltip,
            tooltipOverride,
            className,
            demonstrateAbsence
        }) => {
            const scaleX = getScaledX(
                x,
                xOrigin,
                xEnd,
                innerWidth,
                margin.left
            );
            const scaleY = getScaledY(
                y,
                yOrigin,
                yEnd,
                innerHeight,
                margin.top
            );
            scaledPoints.push({
                x: scaleX,
                y: scaleY,
                originalX: x,
                originalY: y,
                showTooltip,
                tooltipOverride,
                className,
                demonstrateAbsence
            });
        }
    );
    return scaledPoints;
}

function getPolyLines(scaledPoints: ScaledPoint[]) {
    const polylines: ScaledPoint[][] = [];
    let polyline: ScaledPoint[] = [];

    scaledPoints.forEach((point, index) => {
        if (!point.x || !point.y) {
            polylines.push(polyline);
            polyline = [];
            return;
        }
        if (point.demonstrateAbsence) {
            polylines.push(polyline);
            polyline = [];
            return;
        }

        const isLastPoint = index === scaledPoints.length - 1;
        if (isLastPoint) {
            polyline.push(point);
            polylines.push(polyline);
            polyline = [];
            return;
        }

        polyline.push(point);
    });

    return polylines;
}
