import { ApiResponse } from "@services/api-response";
import {
    DataPoint,
    ModelData,
    ScoringMessagesData,
    Site,
    Extruder,
    LabMIDataPoint
} from "./model";

export const ServiceKey = "RheometerService";

export type RheometerPredictionCallback = (
    response: ApiResponse<DataPoint>
) => void;

export type RheometerLabMICallback = (
    response: ApiResponse<LabMIDataPoint>
) => void;

export interface RheometerService {
    /** Subscribe to updates */
    SubscribeToUpdates: (
        site: string,
        extruder: string,
        predictionCallback: RheometerPredictionCallback,
        labMICallback: RheometerLabMICallback
    ) => void;

    /** Unsubscribe from updates */
    UnsubscribeFromUpdates: (
        site: string,
        extruder: string,
        predictionCallback: RheometerPredictionCallback,
        labMICallback: RheometerLabMICallback
    ) => void;

    /** Get Historical Values */
    GetHistoryAsync: (
        site: string,
        extruder: string,
        start: Date,
        end: Date
    ) => Promise<ApiResponse<DataPoint[]>>;

    /** Get Model Data */
    GetModelAsync: (model: string) => Promise<ApiResponse<ModelData>>;

    /** Get Scoring Messages */
    GetScoringMessages: (
        runId: string
    ) => Promise<ApiResponse<ScoringMessagesData>>;

    /** Get Sites Data */
    GetSitesAsync: () => Promise<ApiResponse<Site[]>>;

    /** Get Extruders Data */
    GetExtrudersAsync: (site?: string) => Promise<ApiResponse<Extruder[]>>;

    /** Get Lab MI Data */
    GetLabMeltIndexAsync: (
        site: string,
        extruder: string,
        start: Date,
        end: Date
    ) => Promise<ApiResponse<LabMIDataPoint[]>>;
}
