import React from "react";
import { SpyItem } from "../../hooks/use-scrollspy";

interface NavItemProps {
    item: SpyItem;
}

export function NavItem({ item }: NavItemProps): JSX.Element {
    const cn = item.inView ? "is-active" : "";
    return (
        <li className={cn}>
            <a href={`#${item.entry.id}`}>{item.entry.name}</a>
        </li>
    );
}
