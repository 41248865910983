import React from "react";
import {
    BestPractices,
    Features,
    ProductSupport,
    SolutionOverview
} from "./sections";

export const sections = [
    {
        id: "product-support",
        name: "Product Support"
    },
    {
        id: "solution-overview",
        name: "Solution Overview"
    },
    {
        id: "features",
        name: "Features"
    },
    {
        id: "best-practices",
        name: "Best Practices and Use"
    }
];

export const documentation = (
    <>
        <ProductSupport />
        <SolutionOverview />
        <Features />
        <BestPractices />
    </>
);
