import { LabMIDataPoint, PredictionDataPoint } from "@services";

export default function composeAndShiftAllData(
    existingData: PredictionDataPoint[] | LabMIDataPoint[],
    incomingDataPoint: PredictionDataPoint | LabMIDataPoint
): (PredictionDataPoint | LabMIDataPoint)[] {
    if (existingData.length === 0) {
        return [incomingDataPoint];
    }
    const lastKnownDataPoint = existingData[existingData.length - 1];
    if (
        incomingDataPoint.timeStamp.valueOf() >
        lastKnownDataPoint.timeStamp.valueOf()
    ) {
        const existingDataMinusEarliestPoint = [...existingData];
        existingDataMinusEarliestPoint.shift();
        return [...existingDataMinusEarliestPoint, incomingDataPoint];
    }

    return existingData;
}
