export function getScaledY(
    y: number | null,
    origin: number,
    end: number,
    innerHeight: number,
    marginTop: number
): number | null {
    if (!y) {
        return null;
    }
    const adj = y - origin;
    const adjEnd = end - origin;
    const percent = adj / adjEnd;

    const result = Math.round(innerHeight - percent * innerHeight + marginTop);

    return result;
}
