import React from "react";
import { faScalpelLineDashed } from "@fortawesome/pro-solid-svg-icons";
import "../icon-button/style.scss";
import { Chart_Elements } from "../chart/elements";
import { IconButton } from "../icon-button";

export interface ConnectLineButtonProps {
    className?: string;
    onClick: () => void;
    isConnected?: boolean;
    chartTestId: string;
}

export function ConnectLineButton({
    className,
    onClick,
    isConnected,
    chartTestId
}: ConnectLineButtonProps): JSX.Element {
    return (
        <IconButton
            className={className}
            onClick={onClick}
            ariaLabel={
                isConnected
                    ? "Disconnects Lab Data Points"
                    : "Connects Lab Data Points"
            }
            isActive={isConnected}
            icon={faScalpelLineDashed}
            testId={`${chartTestId}-${Chart_Elements.Connect_Line_Button}`}
        />
    );
}
