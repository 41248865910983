/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { AccountInformation } from "@cpchem/azure-auth";

export interface AuthContextProps {
    currentAccount: AccountInformation | null;
    isLoggedIn: boolean;
    login: () => void;
    logout: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
    currentAccount: null,
    isLoggedIn: false,
    login: () => {},
    logout: () => {}
});
