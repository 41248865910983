import * as msal from "@azure/msal-browser";
import { LogLevel } from "@cpchem/logging";
export interface ProvidedConfig {
    api: {
        rheometer: {
            /** Rheometer API base */
            url: string;
            /** Needed Scopes */
            scopes: string[];
        };
    };
    msal: msal.Configuration;
    logging: {
        appInsights: {
            connectionString: string;
            sinkPageViews?: boolean;
            sinkLogsAtOrAboveLevel?: LogLevel;
        };
    };
    launchDarklyClientSideId: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProvidedConfig(env?: string): ProvidedConfig {
    return env && env === "development"
        ? {
              api: {
                  rheometer: {
                      url: "https://api-dev.cpchem.com/mlrheometer/v1/predictions",
                      scopes: [
                          "https://api-dev.cpchem.com/MLRheometer.FullAccess"
                      ]
                  }
              },
              msal: {
                  auth: {
                      clientId: "1e896970-c7a0-4a92-a2fe-c37e04f019bd",
                      authority:
                          "https://login.microsoftonline.com/88b6c77b-f4e0-40c5-9fbb-df51a927179a"
                  }
              },
              logging: {
                  appInsights: {
                      connectionString:
                          "InstrumentationKey=d746f8db-59f6-490f-af80-e518ce613ef1;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/"
                  }
              },
              launchDarklyClientSideId: "6423022137bc6c1374a5d3b1"
          }
        : getConfigFromIndex();
}

function getConfigFromIndex(): ProvidedConfig {
    const windowConfig = document.getElementById("config")?.innerHTML;
    if (windowConfig) {
        return JSON.parse(windowConfig);
    }
    throw "Unable to find config in page";
}
