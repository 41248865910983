import { MenuItem } from "@cpchem/covalence-ui";
import { AuthorizedExtruder } from "../../../hooks/use-multi-chart";

export function getMenuItemsFromExtruders(
    authorizedExtruders: AuthorizedExtruder[],
    onRequestChangeExtruder: (extruder: string) => void
): MenuItem[] {
    const menuItems = authorizedExtruders
        .filter((extruder) => !!extruder && !extruder.isActive)
        .map((extruder) => {
            const { siteCode, displayName, name } = extruder;
            return {
                key: `${siteCode}-${name}`,
                content: displayName,
                onClick: () => onRequestChangeExtruder(name)
            } as MenuItem;
        });

    return menuItems;
}
