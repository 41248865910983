export const Chart_Elements = {
    Play_Pause_Button: "play-pause-button",
    Connect_Line_Button: "connect-line-button",
    Train_Header: "train-header",
    Close_Button: "close-button",
    Main_Plot: "main-plot",
    Resin_Header: "resin-header",
    Chart_Title: "chart-title",
    Chart_Overlay: "chart-overlay",
    Fast_Backwards_Button: "fast-backwards-button",
    Fast_Forwards_Button: "fast-forwards-button",
    Extend_Time_Button: "extend-time-button",
    Shrink_Time_Button: "shrink-time-button",
    No_Data_Message: "no-data-message"
};
