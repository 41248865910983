import React from "react";
import { ProductTransitionsImg } from "../assets/img";
import { Section } from "../components/section";

export function BestPractices() {
    return (
        <Section id="best-practices">
            <h2>Best practices for the use of the Digital Rheometer</h2>
            <p>
                Scenarios that lead to utilizing the Digital Rheometer to
                produce on-spec material:
            </p>

            <br />
            <h2>Product Transitions</h2>
            <p>
                Below is an example of what is reflected on the Digital
                Rheometer screen when a product transition occurs. The MI
                prediction from the Digital Rheometer can be used to determine
                when the target product MI is back on-spec and acceptable to cut
                a short lot knowing you are on-spec before receiving back lab
                samples.
            </p>
            <img src={ProductTransitionsImg} alt="Product Transitions" />

            <br />
            <h2>Commingling</h2>
            <p>
                Commingling is performed when it is necessary to blend fluff,
                which may or may not be made within the intended reactor target
                Melt Index. When commingling from two or more silos, no less
                than a 5 to 1 ratio of in-range fluff to out-of-range fluff
                should be blended. A maximum level of 25,000 lb of fluff should
                be maintained in an Extruder Feed Tank when commingling so
                off-spec pellet production can be minimized in case of blend
                ratio error.
            </p>
            <p>
                When commingling, pay close attention to any significant Melt
                Index changes on the Digital Rheometer reading to help guide
                commingling activities/blend ratios. Depending on extrusion rate
                and feed tank level, you could see the effect of
                blending/commingling within an hour. When commingling,
                communicate with the Operator retrieving the sample to ensure
                the sample taken is representative of the new conditions with
                commingling (for example, if commingling activities took place
                for the majority of the 2-hour period the sample jar should be
                representative of the commingle).
            </p>

            <br />
            <h2>Reactor Upsets</h2>
            <p>
                There will be instances when reactor upsets or “swings,” such as
                a catalyst feeder issue, will cause undesirable reactor
                conditions for producing on-spec material (ethylene in flash gas
                significantly above or below the desired set point, significant
                rate swing, etc.). If possible, segregate the material made
                during the upset and utilize the Digital Rheometer to commingle
                this material to stay on-spec.
            </p>

            <br />
            <h2>Reactor Startup</h2>
            <p>
                During a reactor start-up, there will be fluff produced during
                at least the first couple of hours that will be off-spec that
                should be able to be commingled in varying blend ratios
                depending on the quality/smoothness of the reactor startup. When
                starting up the extruder, get behind the reactor to quickly
                determine that the material being produced on the reactor is
                on-spec. Once confirmed, a slight commingle can begin to deplete
                startup material inventory. Having accurate, real-time MI from
                the Digital Rheometer following a reactor startup should allow
                you to commingle/blend with high confidence.
            </p>

            <br />
            <h2>High Fluff Inventory</h2>
            <p>
                High fluff inventory can be encountered for various reasons,
                which typically results in the reactor rate being lowered.
                During periods of high fluff inventory, utilize the Digital
                Rheometer to help guide commingling activities. Additionally,
                the Digital Rheometer can be used to quickly gauge what is being
                made on the reactor side by pulling directly behind the reactor
                to the extruder.
            </p>

            <br />
            <h2>Significant Reactor Rate Changes</h2>
            <p>
                It is sometimes necessary to drastically reduce the reactor rate
                (for example, ethylene curtailment, extended unplanned extruder
                downtime, and planned extruder maintenance). Use the Digital
                Rheometer while communicating with the Reactor Board Operator to
                help keep material on-spec while reducing reactor rate. If
                possible, run directly behind the reactor when reducing reactor
                rate to get the most up-to-date results.
            </p>
        </Section>
    );
}
