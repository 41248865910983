import React from "react";

export default function FormattedDateIndicator(
    x1: number,
    height: number,
    index: number,
    positions: number[],
    tickValue: string | number | Date
): JSX.Element {
    const date = new Date(tickValue);
    const xAdjustment = index !== 0 && index !== positions.length - 1 ? 30 : 0;
    const yAdjustment = 10;
    const textAnchor = index === positions.length - 1 ? "end" : "start";
    return (
        <text
            textAnchor={textAnchor}
            x={x1 - xAdjustment}
            y={height - yAdjustment}
            className="date-indicator"
        >
            {date.toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
            })}
        </text>
    );
}
