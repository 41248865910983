import { LabMIDataPoint, PredictionDataPoint } from "@services";
import { TimeSlot } from "./get-windowed-slots";

export function getPredictionWindowedData(
    predictedData: PredictionDataPoint[],
    windowTimeSlots: TimeSlot[]
): PredictionDataPoint[] {
    return windowTimeSlots.map((slot) => {
        const sl_Day = slot.timeStamp.getDay();
        const sl_Hours = slot.timeStamp.getHours();
        const sl_Minutes = slot.timeStamp.getMinutes();
        const sl_MatchTime = `${sl_Day} ${sl_Hours}:${sl_Minutes}`;

        const correspondingDataPoint = predictedData.filter((dp) => {
            const dp_Day = dp.timeStamp.getDay();
            const dp_Hours = dp.timeStamp.getHours();
            const dp_Minutes = dp.timeStamp.getMinutes();
            const dp_MatchTime = `${dp_Day} ${dp_Hours}:${dp_Minutes}`;
            return sl_MatchTime === dp_MatchTime;
        });

        const dpToFill = correspondingDataPoint[0] || null;

        return {
            ...dpToFill,
            timeStamp: slot.timeStamp
        };
    });
}

export function getLabMIWindowedData(
    labData: LabMIDataPoint[],
    windowTimeSlots: TimeSlot[]
): LabMIDataPoint[] {
    return windowTimeSlots.map((slot) => {
        const sl_Day = slot.timeStamp.getDay();
        const sl_Hours = slot.timeStamp.getHours();
        const sl_Minutes = slot.timeStamp.getMinutes();
        const sl_MatchTime = `${sl_Day} ${sl_Hours}:${sl_Minutes}`;

        const correspondingDataPoint = labData.filter((dp) => {
            const dp_Day = dp.timeStamp.getDay();
            const dp_Hours = dp.timeStamp.getHours();
            const dp_Minutes = dp.timeStamp.getMinutes();
            const dp_MatchTime = `${dp_Day} ${dp_Hours}:${dp_Minutes}`;
            return sl_MatchTime === dp_MatchTime;
        });

        const dpToFill = correspondingDataPoint[0] || null;

        return {
            ...dpToFill,
            timeStamp: slot.timeStamp
        };
    });
}
