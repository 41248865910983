import React from "react";
import { Button } from "@cpchem/covalence-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./style.scss";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export interface ChartLayoutButtonProps {
    className?: string;
    icon: IconDefinition;
    onClick: () => void;
    isActive?: boolean;
    ariaLabel: string;
    testId?: string;
}

export function ChartLayoutButton({
    className,
    icon,
    onClick,
    isActive,
    ariaLabel,
    testId
}: ChartLayoutButtonProps): JSX.Element {
    let cn = "chart-layout-button";
    const active_cn = "active";

    if (className) {
        cn += ` ${className}`;
    }

    if (isActive) {
        cn += ` ${active_cn}`;
    }

    return (
        <Button
            testId={testId}
            className={cn}
            onClick={onClick}
            aria-label={ariaLabel}
            isDisabled={isActive}
            variant="text"
            icon={<FontAwesomeIcon className="icon" icon={icon as IconProp} />}
        />
    );
}
