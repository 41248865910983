import { ProvidedConfig, getProvidedConfig } from "./utilities/provided-config";
import "./utilities/cypress-global-extension";

interface Config extends ProvidedConfig {
    env: string;
    connected: boolean;
    pollingFrequencyInMS: number;
    autoRefreshTargetTime: string;
}

// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;
const envConnected = import.meta.env.VITE_CONNECTED
    ? import.meta.env.VITE_CONNECTED === "true"
    : false;
const connected = env === "production" || envConnected;
// eslint-disable-next-line no-undef
const pollingFrequencyInMS = import.meta.env.DEV ? 5 * 1000 : 30 * 1000;
// Uses 24-hour time
const autoRefreshTargetTime = "06:00:00";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const providedConfig: ProvidedConfig = getProvidedConfig(env);

const combinedConfig: Config = {
    ...providedConfig,
    env: env || "unknown",
    connected,
    pollingFrequencyInMS,
    autoRefreshTargetTime
};

export default combinedConfig;
