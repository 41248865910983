import React, { useState, useEffect } from "react";
import "./header.scss";
import { useAuth } from "@hooks";
import { AppBar, useTheme, useNotifications } from "@cpchem/covalence-ui";
import SampleAvatarPicture from "./avatarsample.png";
import DarkLogo from "../../../../assets/dark-dpo-logo.svg";
import LightLogo from "../../../../assets/light-dpo-logo.svg";
import { useNavigate } from "react-router-dom";
import { getUserConfig, UserConfig } from "@utilities/get-user-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import NotificationIcon from "./components/notification-icon";
export interface HeaderProps {
    className?: string;
    applicationName: string;
}

export function Header({ applicationName }: HeaderProps): JSX.Element {
    const [userConfig, setUserConfig] = useState<UserConfig>();

    const { theme } = useTheme();
    const { currentAccount, logout } = useAuth();
    const navigate = useNavigate();
    const { handleOpenDrawer, numberOfNotifications } = useNotifications();

    function handleOnDocumentationClick() {
        navigate("/documentation");
    }

    function handleOnIconClick() {
        navigate("/");
    }

    useEffect(() => {
        if (currentAccount?.username) {
            const currentUserConfig = getUserConfig(currentAccount.username);
            setUserConfig(currentUserConfig);
        }
    }, [currentAccount]);

    const userName = userConfig?.isKiosk ? "" : currentAccount?.username;
    const displayName = userConfig?.isKiosk ? "" : currentAccount?.name;
    const appBarLogo = theme === "dark" ? DarkLogo : LightLogo;

    return (
        <AppBar
            applicationName={applicationName}
            avatarMenu={{
                avatar: {
                    alt: "Some Guy",
                    src: SampleAvatarPicture
                },
                displayName: displayName,
                showLogout: true,
                showThemeSelector: true,
                userName: userName,
                onRequestLogout: logout
            }}
            icon={
                <img
                    className="logo"
                    src={appBarLogo}
                    alt="'Digital Rheometer Logo"
                />
            }
            className="app-bar"
            onIconClick={handleOnIconClick}
            leftMenus={[
                {
                    icon: (
                        <NotificationIcon
                            notifications={numberOfNotifications}
                        />
                    ),
                    onClick: handleOpenDrawer
                },
                {
                    icon: (
                        <FontAwesomeIcon
                            icon={faQuestionCircle as IconProp}
                            className="appbar-button"
                        />
                    ),
                    onClick: handleOnDocumentationClick
                }
            ]}
        />
    );
}
