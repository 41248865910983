import React from "react";
import "./pill.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Pill_Elements } from "./elements";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
export interface PillProps {
    className?: string;
    name: string;
    value: number | null;
    status?: string;
    decimalPoints?: number;
    icon?: IconDefinition;
    chartTestId: string;
}

export function Pill({
    className,
    name,
    value,
    status,
    decimalPoints = 2,
    icon,
    chartTestId
}: PillProps): JSX.Element {
    let cn = "pill";
    if (className) {
        cn += ` ${className}`;
    } else if (status) {
        cn += ` ${status}`;
    }

    const valueContainerTestId = `${chartTestId}-${Pill_Elements.Pill_Container_Root}${name}`;
    const valueTestId = `${chartTestId}-${Pill_Elements.Pill_Value_Root}${name}`;
    return (
        <div className={cn} data-testid={valueContainerTestId}>
            <div className="name">{name}</div>
            <div className="value-container">
                {icon && (
                    <FontAwesomeIcon icon={icon as IconProp} className="icon" />
                )}
                <div className="value" data-testid={valueTestId}>
                    {typeof value !== "undefined" && value !== null
                        ? value.toFixed(decimalPoints)
                        : "N/A"}
                </div>
            </div>
        </div>
    );
}
