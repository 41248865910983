// eslint-disable-next-line @typescript-eslint/no-explicit-any
const instances: { [key: string]: any } = {};

export default {
    set<T>(serviceId: string, instance: T): void {
        instances[serviceId] = instance;
    },
    get<T>(serviceId: string): T {
        return instances[serviceId];
    }
};
