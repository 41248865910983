import { addMinutes, getCurrentDate } from "@utilities/date";
import getLatestReasonableChartWindowEnd from "./get-latest-reasonable-chart-window-end";

export default function getNewWindowEndDate(
    currentWindowEnd: Date,
    minutesToAdjust: number
): Date {
    const endDate = addMinutes(currentWindowEnd, minutesToAdjust);
    const now = getLatestReasonableChartWindowEnd(getCurrentDate);
    return endDate.valueOf() > now.valueOf()
        ? (currentWindowEnd as Date)
        : endDate;
}
