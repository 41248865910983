import { DataPoint, PredictionDataPoint } from "@services";

export function composeChartDataPoint(
    incomingDataPoint: DataPoint,
    lastPredictedValue: number | null | undefined
): PredictionDataPoint {
    const isMissingPrediction =
        incomingDataPoint && !incomingDataPoint.predicted;
    const fallbackPredictedValue = incomingDataPoint.target;
    return {
        dataPoint: {
            ...incomingDataPoint,
            predicted: isMissingPrediction
                ? lastPredictedValue || fallbackPredictedValue
                : incomingDataPoint.predicted
        },
        timeStamp: incomingDataPoint?.timeStamp,
        hasMissingPrediction: isMissingPrediction
    };
}
