import { useEffect, useState, useCallback } from "react";
import { initializeDefaultChartValues } from "./initialize-default-chart-values";
import { updateActiveExtruders } from "./update-active-extruders";
import { RheometerService } from "@services";
import { Extruder, Site } from "@services/rheometer/model";
import { ChartEngineException } from "../components/chart/utils/chart-constants";

export interface Chart {
    chartName: string;
    site: string;
    extruder: string;
    isActive: boolean;
}

export interface AuthorizedExtruder {
    displayName: string;
    siteCode: string;
    siteName: string;
    name: string;
    isActive: boolean;
}

export enum ChartLayout {
    Single = 1,
    Dual = 2
}

interface UseMultiChartReturn {
    charts: Chart[];
    authorizedExtruders: AuthorizedExtruder[];
    chartLayout: ChartLayout;
    sites: Site[] | undefined;
    onRequestSelectExtruder: (
        chartIndex: number,
        extruderSelection: string
    ) => void;
    onRequestDismiss: (chartName: string) => void;
    onRequestLayoutChange: (chartLayout: ChartLayout) => void;
}

export function useMultiChart(
    rheometerService: RheometerService,
    userId: string | undefined,
    onHandleException: (exception: ChartEngineException) => void,
    maxLayoutConfigurations = 2
): UseMultiChartReturn {
    const [charts, setCharts] = useState<Chart[]>([]);
    const [authorizedExtruders, setAuthorizedExtruders] = useState<
        AuthorizedExtruder[]
    >([]);
    const [chartLayout, setChartLayout] = useState<ChartLayout>(
        ChartLayout.Dual
    );
    const [sites, setSites] = useState<Site[]>();
    const [extruders, setExtruders] = useState<Extruder[]>();

    const onRequestSelectExtruder = useCallback(
        (chartIndex: number, extruderSelection: string) => {
            const updatedCharts = handleExtruderSelection(
                authorizedExtruders,
                chartIndex,
                charts,
                extruderSelection
            );
            setCharts(updatedCharts);
        },
        [authorizedExtruders, charts]
    );

    const onRequestDismiss = useCallback(
        (chartName: string) => {
            const updatedCharts = handleDimissChart(charts, chartName);
            setCharts(updatedCharts);
            setChartLayout(ChartLayout.Single);
        },
        [charts]
    );

    function onRequestLayoutChange(chartLayout: ChartLayout) {
        const newCharts = nextChartLayoutState[chartLayout](charts);
        setCharts(newCharts);
        setChartLayout(chartLayout);
    }

    async function getSites(): Promise<Site[]> {
        const { data, error } = await rheometerService.GetSitesAsync();

        if (data) {
            return data;
        }

        if (error) {
            onHandleException({
                message: error,
                severity: "danger",
                autoDismiss: true,
                autoDismissDelay: 10000
            });
        }

        return [];
    }

    async function getExtrudersFromSites(sites: Site[]) {
        try {
            const extruderPromises: Promise<Extruder[] | undefined>[] = [];

            sites.forEach(async (site) => {
                const promise = async () => {
                    const { data } = await rheometerService.GetExtrudersAsync(
                        site.name
                    );
                    return data;
                };
                extruderPromises.push(promise());
            });

            const availableExtruders = await Promise.all(extruderPromises);

            return Array.prototype.concat.apply([], availableExtruders);
        } catch (error) {
            onHandleException({
                message:
                    "Unable to fetch extruders from sites. Please try again or refresh the page.",
                severity: "danger",
                autoDismiss: true,
                autoDismissDelay: 10000
            });
            return [];
        }
    }

    useEffect(() => {
        async function getSitesAndExtruders() {
            const availableSites = await getSites();
            const availableExtruders = await getExtrudersFromSites(
                availableSites
            );
            setSites(availableSites);
            setExtruders(availableExtruders);
        }
        if (userId) {
            getSitesAndExtruders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        if (sites && extruders) {
            const avilableExtuders = extruders as Extruder[];
            const authorizedExtruders = initializeAuthorizedExtruders(
                avilableExtuders,
                sites as Site[]
            );
            const chartsToDisplay = initializeCharts(maxLayoutConfigurations);
            const defautlCharts = initializeDefaultChartValues(
                authorizedExtruders,
                chartsToDisplay
            );
            setAuthorizedExtruders(authorizedExtruders);
            setCharts(defautlCharts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sites, extruders]);

    useEffect(() => {
        if (charts.length !== 0) {
            const updatedExtruders = updateActiveExtruders(
                authorizedExtruders,
                charts
            );
            setAuthorizedExtruders(updatedExtruders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [charts]);

    return {
        charts,
        authorizedExtruders,
        chartLayout,
        sites,
        onRequestSelectExtruder,
        onRequestDismiss,
        onRequestLayoutChange
    };
}

interface ChartOptions {
    isActive?: boolean;
    chartName?: string;
    site?: string;
    extruder?: string;
}
export function handleCreateChart(options?: ChartOptions): Chart {
    const newChart = {
        isActive: true,
        chartName: "",
        site: "",
        extruder: "",
        ...options
    } as Chart;
    return newChart;
}

const nextChartLayoutState = {
    [ChartLayout.Single]: (existingCharts: Chart[]): Chart[] => {
        const updatedCharts = existingCharts[0];
        return [updatedCharts];
    },
    [ChartLayout.Dual]: (existingCharts: Chart[]): Chart[] => {
        const newChart = handleCreateChart();
        return [...existingCharts, newChart];
    }
};

export function initializeAuthorizedExtruders(
    extruders: Extruder[],
    sites: Site[]
): AuthorizedExtruder[] {
    return extruders.map((extruder) => {
        const siteFromExtruder = sites.filter(
            (site) => site.name === extruder.site
        )[0];
        return {
            name: extruder.name,
            displayName: extruder.displayName,
            siteCode: extruder.site,
            siteName: siteFromExtruder.displayName,
            isActive: false
        } as AuthorizedExtruder;
    });
}

export function initializeCharts(maxLayoutConfigurations: number): Chart[] {
    const charts = [];
    for (let i = 1; i <= maxLayoutConfigurations; i++) {
        const newChart = handleCreateChart({ chartName: `Chart-${i}` });
        charts.push(newChart);
    }
    return charts;
}

export function handleDimissChart(
    existingCharts: Chart[],
    chartName: string
): Chart[] {
    const updatedCharts = [...existingCharts].filter(
        (chart) => chart.chartName !== chartName
    );
    return updatedCharts;
}

export function handleExtruderSelection(
    authorizedExtruders: AuthorizedExtruder[],
    chartIndex: number,
    existingCharts: Chart[],
    extruderSelection: string
): Chart[] {
    const updatedCharts = [...existingCharts];
    const selectedExtruder = authorizedExtruders.filter(
        (extruder) => extruder.name === extruderSelection
    );
    updatedCharts[chartIndex] = {
        chartName: selectedExtruder[0].displayName,
        extruder: selectedExtruder[0].name,
        site: selectedExtruder[0].siteCode,
        isActive: true
    };
    return updatedCharts;
}
