import { isDate } from "@utilities/date";
import { XType } from "../models/x-type";

export function getScaledX(
    x: XType,
    origin: XType,
    end: XType,
    innerWidth: number,
    marginLeft: number
): number {
    let adj;
    let adjEnd;
    if (isDate(x)) {
        const xMilliseconds = x.getTime();
        const originMilliseconds = (origin as Date).getTime();
        const endMilliseconds = (end as Date).getTime();

        adj = xMilliseconds - originMilliseconds;
        adjEnd = endMilliseconds - originMilliseconds;
    } else {
        adj = x - (origin as number);
        adjEnd = (end as number) - (origin as number);
    }

    return Math.round((adj / adjEnd) * innerWidth + marginLeft);
}
