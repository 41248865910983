import React from "react";
import { Menu } from "@cpchem/covalence-ui";
import { AuthorizedExtruder } from "../../../hooks/use-multi-chart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getMenuItemsFromExtruders } from "../utils/get-menu-items-from-extruders";
import { Chart_Elements } from "../elements";

interface ChartHeaderProps {
    className?: string;
    chartName: string;
    authorizedExtruders: AuthorizedExtruder[];
    onRequestChangeExtruder: (extruder: string) => void;
    chartTestId: string;
}
export default function ChartHeader({
    className,
    chartName,
    authorizedExtruders,
    onRequestChangeExtruder,
    chartTestId
}: ChartHeaderProps): JSX.Element {
    let cn = "chart-header";
    if (className) {
        cn += ` ${className}`;
    }

    const menuItems = getMenuItemsFromExtruders(
        authorizedExtruders,
        onRequestChangeExtruder
    );

    const hasMenuItems = menuItems.length !== 0;

    return (
        <div className={cn}>
            <Menu
                baseElement={
                    <div className="title">
                        <h2
                            data-testid={`${chartTestId}-${Chart_Elements.Chart_Title}`}
                        >
                            {chartName}
                        </h2>
                        {hasMenuItems && (
                            <FontAwesomeIcon
                                icon={faChevronDown as IconProp}
                                className="icon"
                            />
                        )}
                    </div>
                }
                menuItems={menuItems}
                className="menu"
            />
        </div>
    );
}
