export function precisionRound(number: number, precision: number): number {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
}

export function nullablePrecisionRound(
    number: number | null,
    precision: number
): number | null {
    if (!number) {
        return null;
    }
    return precisionRound(number, precision);
}
