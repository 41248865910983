import { useState, useEffect } from "react";

function useMediaQuery(query: string): boolean {
    const [matchQuery, setMatchQuery] = useState<boolean>(false);

    useEffect(() => {
        function handleMatchQuery() {
            const { matches } = window.matchMedia(query);
            setMatchQuery(matches);
        }

        const matchMedia = window.matchMedia(query);

        handleMatchQuery();

        matchMedia.addEventListener("change", handleMatchQuery);

        return () => {
            matchMedia.removeEventListener("change", handleMatchQuery);
        };
    }, [query]);

    return matchQuery;
}

export default useMediaQuery;
