export interface UserConfig {
    facility?: string;
    board?: string;
    site?: string;
    isKiosk: boolean;
}
export function matcheKioskUserId(userId: string) {
    const regex = /ksk-.*@cpchem\.com/i;
    return regex.test(userId);
}

export function getUserConfig(userId: string): UserConfig {
    const lowerCasedUserId = userId.toLocaleLowerCase();
    const isKiosk = matcheKioskUserId(lowerCasedUserId);

    if (isKiosk) {
        return { facility: "CPCHEM", board: "KIOSK", isKiosk: true };
    }

    return {
        facility: "CPCHEM",
        board: "ADMIN",
        isKiosk: false
    };
}
